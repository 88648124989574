import {
  Typography,
  useTheme,
  Box,
  Button,
  Dialog,
  DialogActions,
  Menu,
  MenuItem,
  DialogContent,
  DialogTitle,
  Divider,
} from '@mui/material';

import { FC, useId, useState } from 'react';
import React from 'react';
import { FilePlaceholder } from './FilePlaceholder';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

export interface AttachmentProps {
  onFileSelected: (file: File) => void;
  onDownload?: (filename: string) => void;
  progress?: number;
  label?: string;
  helpModal?: React.ReactNode;
  filename?: string;
  error?: Error;
  onRemove: (filename: string) => void;
  required?: boolean;
}

const Attachment: FC<AttachmentProps> = ({
  onFileSelected,
  onDownload,
  progress = 0,
  label,
  filename,
  helpModal,
  error,
  onRemove,
  required = false,
}) => {
  const uploadComplete = progress === 100;
  const theme = useTheme();
  const fileRef = React.useRef<HTMLInputElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const [showMenu, setShowMenu] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const uuid = useId();
  const [alreadyUploaded, setAlreadyUploaded] = useState(uploadComplete);

  const handleFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    setValidationMessage('');

    if (files && files.length > 0) {
      onFileSelected && onFileSelected(files[0]);
    }
  };
  const handleAttachmentClick = () => {
    if (error) {
      setShowErrorModal(true);
      return;
    }
    if (!!filename) {
      setShowMenu(true);
    } else {
      if (helpModal) {
        setShowHelpModal(true);
      } else {
        fileRef.current?.click();
      }
    }
  };

  const handleModalUploadButton = () => {
    setShowHelpModal(false);
    fileRef.current?.click();
  };

  const handleHelpModalonClose = () => {
    setShowHelpModal(false);
  };

  const handleCloseMenu = () => {
    setShowMenu(false);
  };

  const handleRemove = () => {
    handleCloseMenu();
    handleErrorModalonClose();
    setValidationMessage('');
    fileRef.current!.value = '';
    inputRef.current!.value = '';
    setAlreadyUploaded(false);
    onRemove(filename!);
  };

  const handleDownload = () => {
    handleCloseMenu();
    onDownload && onDownload(filename!);
    // TODO: download file
  };

  const handleChange = () => {
    handleCloseMenu();
    handleErrorModalonClose();
    fileRef.current?.click();
  };

  const handleErrorModalonClose = () => {
    setShowErrorModal(false);
  };

  const handleOnInvalid = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValidationMessage(event.target.validationMessage);
  };

  let labelColor = uploadComplete
    ? theme.palette.primary.main
    : theme.palette.primary.light;

  if (!!error || !!validationMessage) {
    labelColor = theme.palette.error.main;
  }

  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
      <Dialog
        open={showHelpModal}
        onClose={handleHelpModalonClose}
        maxWidth={'sm'}
        fullWidth
      >
        {helpModal}
        <DialogActions sx={{ backgroundColor: theme.palette.grey[300] }}>
          <Button variant="outlined" onClick={handleHelpModalonClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleModalUploadButton}>
            Upload
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showErrorModal}
        onClose={handleErrorModalonClose}
        maxWidth={'sm'}
        fullWidth
      >
        <DialogTitle color={'error'} display={'flex'} alignItems={'center'}>
          <WarningAmberIcon sx={{ marginRight: 1 }} />
          <>{error?.message}</>
        </DialogTitle>
        <DialogContent>
          <>{error?.cause}</>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: theme.palette.grey[300] }}>
          {filename ? (
            <>
              <Button variant="outlined" onClick={handleRemove}>
                Remove
              </Button>
              <Button variant="contained" onClick={handleChange}>
                Upload again
              </Button>
            </>
          ) : (
            <>
              <Button variant="outlined" onClick={handleErrorModalonClose}>
                Close
              </Button>
              <Button variant="contained" onClick={handleChange}>
                Upload
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      <input
        type="file"
        hidden
        onChange={handleFileSelected}
        ref={fileRef}
        id={uuid}
      />

      <input
        hidden
        onChange={() => {}}
        name="attachment"
        value={filename || ''}
        onInvalid={handleOnInvalid}
        required={required}
        ref={inputRef}
      />

      <Menu
        id="basic-menu"
        anchorEl={buttonRef.current}
        open={showMenu}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box
          fontWeight="600"
          padding={1}
          maxWidth="200px"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
        >
          {filename}
        </Box>
        <Divider />
        <MenuItem onClick={handleRemove}>Remove</MenuItem>
        <MenuItem onClick={handleDownload}>Download</MenuItem>
      </Menu>
      <Button
        onClick={handleAttachmentClick}
        title={filename || ''}
        ref={buttonRef}
        data-focusfor={uuid}
      >
        <Box
          position={'relative'}
          sx={{
            animation:
              (!alreadyUploaded && uploadComplete && 'gelatine 0.3s') || 'none',
            animationDelay: '0.6s',
          }}
        >
          <FilePlaceholder
            label={filename || ''}
            uploadComplete={uploadComplete}
            color={theme.palette.primary.light}
            error={!!error || !!validationMessage}
          />

          <Box
            bottom={0}
            borderRadius={2}
            left={0}
            overflow={'hidden'}
            position="absolute"
            right={0}
            top={`${100 - progress}%`}
            sx={{
              transition: 'top 0.6s',
              background:
                'linear-gradient(90deg, #ffd885 0%, #ffc445 20%, #ffc445 80%, #ffd885 100%)',
            }}
          >
            <FilePlaceholder
              color={theme.palette.secondary.dark}
              label={filename || ''}
              uploadComplete={uploadComplete}
              position={'absolute'}
              bottom={0}
              error={!!error || !!validationMessage}
            />
          </Box>
        </Box>
      </Button>

      <Typography
        variant="body2"
        fontWeight={'bold'}
        color={labelColor}
        align="center"
        alignItems={'center'}
        display={'flex'}
        component={'label'}
        htmlFor={uuid}
      >
        {label}
        {required ? '*' : ' (optional)'}
      </Typography>
    </Box>
  );
};

export { Attachment };
