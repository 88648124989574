import { createContext, FC, useEffect, useState } from 'react';

export type Config = {
  apiBase: string;
  amplify: {
    Auth: {
      region: string;
      userPoolId: string;
      userPoolWebClientId: string;
    };
  };
};

const ConfigContext = createContext<Config | null>(null);

interface ConfigProviderProps {
  children: React.ReactNode;
}

const ConfigProvider: FC<ConfigProviderProps> = ({ children }) => {
  const [config, setConfig] = useState<Config | null>(null);

  const getConfig = () => {
    fetch('/config.json')
      .then((res) => res.json())
      .then(setConfig);
  };

  useEffect(() => {
    getConfig();
  }, []);

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};

export { ConfigProvider };
export default ConfigContext;
