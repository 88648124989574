import { Box } from '@mui/material';

const TalkToUs = () => (
  <Box
    mx={1}
    flexDirection="column"
    sx={{
      display: 'flex',
      flexDirection: {
        xs: 'row',
        sm: 'column',
      },
      justifyContent: {
        xs: 'space-between',
        sm: 'flex-end',
      },
      fontWeight: 600,
      alignItems: 'flex-end',
      paddingY: 1,
      fontSize: { xs: 12, sm: '1rem' },
      width: '100%',
    }}
  >
    <Box
      component="span"
      sx={(theme) => ({
        color: theme.palette.secondary.main,
      })}
    >
      Speak to our specialist team
    </Box>
    <Box
      component="span"
      sx={(theme) => ({
        color: theme.palette.primary.contrastText,
      })}
    >
      01254 693 067
    </Box>
  </Box>
);

export default TalkToUs;
