import { Box } from '@mui/material';
import { AccountFieldset } from 'components/fieldsets/personalDetails';
import { Form } from 'components/form';
import { FormErrorMessage } from 'components/form/formErrorMessage';
import { SectionCTAs } from 'components/sectionCTAs';
import { ProfileDTO } from 'generated/profile';

import { FC } from 'react';

interface PersonalDetailsFormProps {
  profile?: {
    forename?: string;
    surname?: string;
    currentAddress?: string;
    mobilenumber?: string;
    homenumber?: string;
    nationality?: string;
    biometricImmigrationDocumentIssued?: boolean;
    nhsemail?: string;
    paytype?: string;
    dob?: string;
  };
  onSubmit: (data: any) => Promise<ProfileDTO>;
}

const PersonalDetailsForm: FC<PersonalDetailsFormProps> = ({
  profile,
  onSubmit,
}) => {
  return (
    <Form
      onSubmit={(formData: FormData) => {
        const data = Object.fromEntries(formData.entries());
        return onSubmit(data);
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
        }}
      >
        <AccountFieldset value={profile} />
        <FormErrorMessage />
        <SectionCTAs nextUrl="/profile/PROFESSIONAL" />
      </Box>
    </Form>
  );
};

export default PersonalDetailsForm;
