import PersonalDetailsForm from 'components/profile/PersonalDetailsForm';
import { PersonalDetailsDTO } from 'generated/profile';
import useProfile from 'hooks/useProfile';
import { FC } from 'react';
import ProfileContainer from './ProfileContainer';

const PersonalDetailsContainer: FC = () => {
  const { profile, saveProfile } = useProfile();
  const handleOnSubmit = (formData: any) =>
    saveProfile({
      ...profile!,
      personalDetails: {
        forename: formData.forename as string,
        surname: formData.surname as string,
        address: formData.currentAddress as string,
        mobileNumber: formData.mobilenumber as string,
        biometricImmigrationDocumentIssued:
          !!formData.biometricImmigrationDocumentIssued,
        homeNumber: formData.homenumber as string,
        nhsEmailAddress: formData.nhsemail as string,
        dateOfBirth: formData.dob as string,
        nationality: formData.nationality as PersonalDetailsDTO.nationality,
        payType: formData.payType as PersonalDetailsDTO.payType,
      },
    });

  return (
    <ProfileContainer
      step="PERSONAL"
      controls={[
        <PersonalDetailsForm
          profile={{
            forename: profile?.personalDetails?.forename || '',
            surname: profile?.personalDetails?.surname || '',
            currentAddress: profile?.personalDetails?.address || '',
            mobilenumber: profile?.personalDetails?.mobileNumber || '',
            homenumber: profile?.personalDetails?.homeNumber || '',
            nhsemail: profile?.personalDetails?.nhsEmailAddress || '',
            dob: profile?.personalDetails?.dateOfBirth || '',
            nationality: profile?.personalDetails?.nationality || '',
            biometricImmigrationDocumentIssued:
              !!profile?.personalDetails?.biometricImmigrationDocumentIssued,
            paytype: profile?.personalDetails?.payType || '',
          }}
          key="personalDetails"
          onSubmit={handleOnSubmit}
        />,
      ]}
    />
  );
};

PersonalDetailsContainer.displayName = 'PersonalDetailsContainer';

export default PersonalDetailsContainer;
