import { Link, Typography } from '@mui/material';
import { ProfileDTO } from 'generated/profile';
import { FC } from 'react';

interface HomeViewInVerificationContentProps {
  profile: ProfileDTO;
}

const HomeViewInVerificationContent: FC<HomeViewInVerificationContentProps> = ({
  profile,
}) => (
  <>
    <>
      <Typography variant="h5" mb={2} fontWeight="600">
        {profile.personalDetails?.forename
          ? `Hi ${profile.personalDetails?.forename},`
          : 'Hello,'}
      </Typography>
      <div>
        <p>Thank you for completing your registration process with us.</p>
        <p>
          Your profile is currently being verified by our compliance team. There
          are no actions required from you at this stage. Please be patient
          while we ensure all the information is accurate and complete.
        </p>
        <p>
          If the compliance team requires any additional information, they will
          get in touch with you directly. Rest assured that we will keep you
          informed throughout the process.
        </p>
        <p>
          In the meantime, if you have any questions or need any assistance,
          feel free to reach out to our team at:
        </p>
        <p>
          Phone:{' '}
          <Link fontWeight={'bold'} href="tel://+441254693067">
            01254 693067
          </Link>
        </p>
        <p>
          Email:{' '}
          <Link
            fontWeight={'bold'}
            href="mailto:compliance@cobenhealthcare.com"
          >
            compliance@cobenhealthcare.com
          </Link>
        </p>
      </div>
    </>
  </>
);

export default HomeViewInVerificationContent;
