/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProfileDTO } from '../models/ProfileDTO';
import type { VerificationDTO } from '../models/VerificationDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CandidateService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get profile
     * Returns the profile for the current user
     * @returns ProfileDTO Success
     * @throws ApiError
     */
    public getSelfProfile(): CancelablePromise<ProfileDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/self/profile',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Create profile
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public createSelfProfile(
        requestBody: ProfileDTO,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/self/profile',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update profile
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public updateSelfProfile(
        requestBody: ProfileDTO,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/self/profile',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Submits profile for verification
     * @returns ProfileDTO Success
     * @throws ApiError
     */
    public postSubmitProfile(): CancelablePromise<ProfileDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/self/profile/submit',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Get profile verification
     * @returns VerificationDTO Success
     * @throws ApiError
     */
    public getSelfProfileVerification(): CancelablePromise<VerificationDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/self/profile/verification',
            errors: {
                404: `Not found`,
            },
        });
    }

}
