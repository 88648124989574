import {
  Box,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import { customErrorMessages } from 'components/form';
import { InputBox } from 'components/inputBox';
import { FC, useState } from 'react';
import { OptionGroup, option } from 'components/optionGroup';
import { DateBox } from 'components/dateBox';

// very generic phone number regex
const phoneRegex = '^[+]*[0-9]{7,13}$';

interface AccountFieldsetProps {
  value?: { [key: string]: any };
}

const nationalityOptions: option[] = [
  {
    value: 'BRITISH',
    label: 'British',
  },
  {
    value: 'EEA',
    label: 'EEA National',
  },
  {
    value: 'OTHER',
    label: 'Other',
  },
];

const payTypeOptions: option[] = [
  {
    value: 'PAYE',
    label: 'PAYE',
  },
  {
    value: 'LTD',
    label: 'Limited Company',
  },
  {
    value: 'SOLE_TRADER',
    label: 'Sole Trader',
  },
];

const AccountFieldset: FC<AccountFieldsetProps> = ({ value }) => {
  const [nationality, setNationality] = useState<string | undefined>(
    value?.nationality,
  );
  const [payType, setPayType] = useState<string | undefined>(value?.paytype);

  const onNationalityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNationality(event.target.value);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <InputBox
        name="forename"
        label="Forename"
        required
        defaultValue={value?.forename}
        errorMessages={customErrorMessages}
      />
      <InputBox
        name="surname"
        label="Surname"
        defaultValue={value?.surname}
        required
      />
      <InputBox
        name="currentAddress"
        label="Current address"
        defaultValue={value?.currentAddress}
        multiline
        rows={4}
        required
      />
      <InputBox
        name="mobilenumber"
        label="Mobile number"
        type="tel"
        defaultValue={value?.mobilenumber}
        inputProps={{ pattern: phoneRegex }}
        required
      />

      <InputBox
        name="homenumber"
        label="Landline number"
        type="tel"
        defaultValue={value?.homenumber}
        inputProps={{ pattern: phoneRegex }}
      />

      <DateBox
        defaultValue={value?.dob}
        label="Date of Birth"
        name="dob"
        inputProps={{
          max: new Date().toISOString().split('T')[0],
        }}
        required
      />

      <InputBox
        name="nhsemail"
        label="NHS Email address"
        type="email"
        defaultValue={value?.nhsemail}
      />

      <OptionGroup
        name="nationality"
        onChange={onNationalityChange}
        options={nationalityOptions}
        title="What is your nationality?"
        row
        sx={{ marginTop: 2 }}
        defaultValue={value?.nationality}
        required
      >
        <Collapse in={!!nationality && nationality !== 'BRITISH'}>
          <Divider sx={{ marginTop: 1, marginBottom: 2 }} />
          <FormControlLabel
            sx={{
              alignItems: 'flex-start',
            }}
            control={
              <Checkbox
                sx={{ marginTop: -1 }}
                name="biometricImmigrationDocumentIssued"
                defaultChecked={value?.biometricImmigrationDocumentIssued}
              />
            }
            label="I have a current Biometric immigration document/permit issued by the Home Office"
          />
        </Collapse>
      </OptionGroup>

      <OptionGroup
        title="Pay type"
        description="Please confirm your chosen pay type"
        name="payType"
        options={payTypeOptions}
        row
        sx={{ marginTop: 2 }}
        onChange={(e) => setPayType(e.target.value)}
        defaultValue={value?.paytype}
        required
      >
        <Collapse in={!!payType && payType !== 'PAYE'}>
          <FormHelperText>
            Please note, some of our clients deem the roles we supply as inside
            of IR35, but our team will advise you of this before putting you
            forward for any work
          </FormHelperText>
        </Collapse>
      </OptionGroup>
    </Box>
  );
};

export { AccountFieldset };
