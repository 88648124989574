import { useAuth } from 'hooks/useAuth';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import LoginView from 'views/LoginView';

export interface LoginError {
  email?: string | null;
  password?: string | null;
  other?: string | null;
}

const LoginContainer = () => {
  const { login, loading } = useAuth();
  const [error, setError] = useState<LoginError | null>(null);
  const navigate = useNavigate();

  const handleLoginSubmit = (email: string, password: string) => {
    return login(email, password)
      .then((result) => {
        if (!result) {
          setError({
            other: 'An unknown error occurred',
          });
        }
        navigate('/', { replace: true });
      })
      .catch((error) => {
        switch (error.code) {
          case 'UserNotConfirmedException':
            navigate('/verify');
            break;
          case 'UserNotFoundException':
            setError({
              email: 'User not found',
            });
            break;
          case 'NotAuthorizedException':
            setError({
              password: 'Incorrect password',
            });
            break;
          default:
            setError({
              other: 'An unknown error occurred',
            });
            break;
        }
        throw error;
      });
  };

  return (
    <LoginView
      loading={loading}
      onSubmit={handleLoginSubmit}
      loginError={error}
      onAlertClose={() => setError(null)}
    />
  );
};

export default LoginContainer;
