import { FormErrorMessage } from 'components/form/formErrorMessage';
import { InputBox } from 'components/inputBox';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import UserView from 'views/UserView';
import { SubmitButton } from 'components/submitButton';
import { Form } from 'components/form';
import { Avatar, Box, Typography } from '@mui/material';
import { NewPassword } from 'components/newPassword';

interface Props {
  loading?: boolean;
  hasEmail?: boolean;
  onSendCode: (data: FormData) => Promise<boolean>;
  onResetPassword: (data: FormData) => Promise<void>;
}
const ForgottenPasswordView = ({
  hasEmail = false,
  loading,
  onSendCode,
  onResetPassword,
}: Props) => {
  return (
    <UserView loading={loading}>
      <Avatar
        className="avatar"
        sx={(theme) => ({ bgcolor: theme.palette.secondary.main })}
      >
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Reset password
      </Typography>
      <Typography component="div" variant="body2">
        {hasEmail ? (
          <Box component={'p'} display={'flex'} flexDirection={'column'}>
            <span>Pease check your email for a message with your code.</span>
            <span>Your email may take a few minutes to arrive.</span>
          </Box>
        ) : (
          <Box component={'p'} display={'flex'} flexDirection={'column'}>
            <span>Please enter the email address you used to register.</span>
            <span>We will send you a code to reset your password.</span>
          </Box>
        )}
      </Typography>
      {hasEmail ? (
        <Form onSubmit={onResetPassword}>
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            <NewPassword label="Password" name="password" />

            <InputBox label="Code" name="code" type="text" />
            <SubmitButton>Reset password</SubmitButton>
            <FormErrorMessage />
          </Box>
        </Form>
      ) : (
        <Form onSubmit={onSendCode}>
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            <InputBox label="Email" name="email" type="email" />
            <SubmitButton>Send code</SubmitButton>
            <FormErrorMessage />
          </Box>
        </Form>
      )}
    </UserView>
  );
};

export default ForgottenPasswordView;
