import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  SelectChangeEvent,
  SelectProps,
  useTheme,
} from '@mui/material';
import { ErrorCodes } from 'components/form';
import { FormContext } from 'components/form/formContext';
import { useState, useRef, useContext, useCallback, useEffect } from 'react';

interface SelectBoxProps extends SelectProps {
  label?: React.ReactNode;
  errorMessages?: Partial<Record<ErrorCodes, string>>;
}

const SelectBox = ({
  label,
  errorMessages,
  onChange,
  required,
  fullWidth,
  children,
  ...rest
}: SelectBoxProps) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { isSubmissionAttempted, setIsDirty } = useContext(FormContext);

  const getErrorCode = () => {
    let errorKey: ErrorCodes | undefined = undefined;
    if (inputRef.current) {
      // can't use Object.keys here

      for (const key in inputRef.current.validity) {
        if (inputRef.current.validity[key as keyof ValidityState]) {
          errorKey = key as ErrorCodes;
          break;
        }
      }

      if (inputRef.current.validity.customError) {
        errorKey = inputRef.current.validationMessage as ErrorCodes;
      }

      return errorKey === 'valid' ? undefined : errorKey;
    }
  };

  const updateErrorMessage = useCallback(() => {
    const errorCode = getErrorCode();

    const isInvalid = isSubmissionAttempted && errorCode ? true : false;
    const errorText = isInvalid
      ? errorMessages?.[errorCode!] || inputRef.current?.validationMessage
      : '';

    setErrorMessage(errorText);
  }, [errorMessages, isSubmissionAttempted]);

  const validateInput = useCallback(async () => {
    if (inputRef.current) {
      const target = inputRef.current;

      // resetting the custom validity state
      target.setCustomValidity('');

      updateErrorMessage();
    }
  }, [updateErrorMessage]);

  const handleInput = (
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode,
  ) => {
    // need to run the validation on the next frame
    requestAnimationFrame(() => {
      validateInput();
      setIsDirty(true);
      onChange && onChange(event, child);
    });
  };

  useEffect(() => {
    inputRef.current = wrapperRef.current!.querySelector('input');
  }, []);

  useEffect(() => {
    // running the validation on mount to set the initial validity state
    validateInput();
  }, [validateInput]);

  const showError = isSubmissionAttempted && !!errorMessage;
  const theme = useTheme();

  return (
    <FormControl required={required} fullWidth={fullWidth}>
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        {...rest}
        sx={{
          color: showError
            ? theme.palette.error.main
            : theme.palette.text.primary,
        }}
        ref={wrapperRef}
        onChange={handleInput}
        error={showError}
      >
        {children}
      </Select>
      <FormHelperText error>{errorMessage}</FormHelperText>
    </FormControl>
  );
};

export { SelectBox };
