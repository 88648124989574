import { Box, BoxProps } from '@mui/material';
import style from './style.module.css';

interface SpinnerProps extends BoxProps {
  variation?: 'light' | 'dark';
}
const Spinner = ({ variation = 'dark', ...rest }: SpinnerProps) => (
  <Box className={`${style.spinner} ${style[variation]}`} {...rest} />
);

export { Spinner };
