import { AddCircleOutline, FilePresentOutlined } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { BoxProps, Box } from '@mui/system';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

interface FilePlaceholderProps extends BoxProps {
  label?: string;
  uploadComplete: boolean;
  error?: boolean;
  color?: string;
  required?: boolean;
}
const FilePlaceholder = ({
  label,
  uploadComplete,
  sx,
  error = false,
  required = false,
  ...rest
}: FilePlaceholderProps) => {
  const theme = useTheme();

  let border = uploadComplete
    ? `2px solid ${theme.palette.common.white}`
    : `2px dashed ${theme.palette.primary.light}`;
  border = error ? `2px dashed ${theme.palette.error.main}` : border;

  const style = {
    ...sx,
    border: border,
    boxShadow: uploadComplete ? '0px 1px 3px rgba(0,0,0,0.3)' : 'none',
  };

  return (
    <Box
      alignItems="center"
      borderRadius={2}
      display="flex"
      flexDirection="column"
      gap={1}
      padding={2}
      sx={style}
      position={'relative'}
      {...rest}
    >
      <Box sx={{ fontSize: '60px' }} display={'flex'}>
        {label ? (
          <>
            <FilePresentOutlined
              fontSize="inherit"
              sx={{ position: 'relative' }}
            />
            {uploadComplete && (
              <MoreVertOutlinedIcon
                sx={{
                  position: 'absolute',
                  top: 5,
                  right: 0,
                }}
              />
            )}
            {error && (
              <WarningAmberIcon
                sx={{
                  position: 'absolute',
                  top: 5,
                  right: 5,
                  fontSize: '1rem',
                }}
              />
            )}
          </>
        ) : (
          <AddCircleOutline fontSize="inherit" sx={{ position: 'relative' }} />
        )}
      </Box>
    </Box>
  );
};

export { FilePlaceholder };
