/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ProfileSummaryDTO = {
    id: string;
    email: string;
    assignedTo?: string;
    forename?: string;
    surname?: string;
    status: ProfileSummaryDTO.status;
};

export namespace ProfileSummaryDTO {

    export enum status {
        DRAFT = 'DRAFT',
        PENDING = 'PENDING',
        IN_PROGRESS = 'IN_PROGRESS',
        ACTION_REQUIRED = 'ACTION_REQUIRED',
        APPROVED = 'APPROVED',
    }


}

