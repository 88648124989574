import { Link, Typography } from '@mui/material';

import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import PostAddIcon from '@mui/icons-material/PostAdd';
import DrawIcon from '@mui/icons-material/Draw';
import { FC } from 'react';
import { Step, VerificationStatus } from 'components/types/verification';
import { VerificationStepStatus } from 'components/types/onboarding';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import VerificationStatusLogs from './VerificationStatusLogs';
import { Link as RouterLink } from 'react-router-dom';

type StepStatusColourType =
  | 'grey'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning';

const stepStatusColor: Record<VerificationStepStatus, StepStatusColourType> = {
  COMPLETED: 'success',
  IN_PROGRESS: 'secondary',
  PENDING: 'grey',
  ACTION_REQUIRED: 'error',
};

const steps: Step[] = [
  { id: 'PERSONAL', label: 'Personal details', icon: EmojiPeopleIcon },
  {
    id: 'PROFESSIONAL',
    label: 'Professional details',
    icon: LocalHospitalIcon,
  },
  { id: 'REFERENCES', label: 'References', icon: LocalHospitalIcon },
  { id: 'DOCUMENTS', label: 'Documents', icon: PostAddIcon },
  { id: 'DECLARATION', label: 'Declaration', icon: DrawIcon },
];

interface VerificationStatusControlProps {
  verificationStatus?: VerificationStatus;
  showLogs?: boolean;
}

const VerificationStatusControl: FC<VerificationStatusControlProps> = ({
  verificationStatus = {
    PERSONAL: { status: 'PENDING' },
    PROFESSIONAL: { status: 'PENDING' },
    REFERENCES: { status: 'PENDING' },
    DOCUMENTS: { status: 'PENDING' },
    DECLARATION: { status: 'PENDING' },
  },
  showLogs,
}) => {
  return (
    <>
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {steps.map((step) => {
          const color = stepStatusColor[verificationStatus[step.id].status];
          const labelColor =
            verificationStatus[step.id].status === 'IN_PROGRESS'
              ? 'success'
              : stepStatusColor[verificationStatus[step.id].status];
          return (
            <TimelineItem key={step.id}>
              <TimelineSeparator>
                <TimelineDot color={color} sx={{ borderWidth: '6px' }}>
                  {<step.icon />}
                </TimelineDot>
                {step.id !== 'DECLARATION' && (
                  <TimelineConnector sx={{ minHeight: '20px' }} />
                )}
              </TimelineSeparator>
              <TimelineContent marginTop={1.5}>
                <Typography color={labelColor} variant="h6">
                  {verificationStatus[step.id].status === 'ACTION_REQUIRED' ? (
                    <Link
                      to={`/profile/${step.id}`}
                      color={'inherit'}
                      underline="hover"
                      display={'flex'}
                      alignItems={'center'}
                      component={RouterLink}
                    >
                      {step.label}
                      <WarningAmberIcon
                        sx={{ marginLeft: '0.5em', fontSize: '1rem' }}
                      />
                    </Link>
                  ) : (
                    step.label
                  )}
                </Typography>
                {verificationStatus[step.id].status === 'IN_PROGRESS' && (
                  <Typography variant="body2" component="p" marginBottom={1}>
                    (in progress)
                  </Typography>
                )}
                {showLogs && (
                  <VerificationStatusLogs
                    logs={verificationStatus[step.id].logs}
                  />
                )}
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    </>
  );
};

export default VerificationStatusControl;
