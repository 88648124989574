import { Box, Button, Typography } from '@mui/material';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const HomeViewAnonymoutContent: FC = () => (
  <Box marginBottom={4}>
    <Typography variant="h4" mb={2}>
      <strong>Welcome to our recruitment onboarding app!</strong>
    </Typography>

    <div>
      <p>
        Create a standout profile that highlights your healthcare expertise
        effortlessly.
      </p>
      <p>
        With user-friendly features, showcase your skills, qualifications, and
        experience to attract top employers.
      </p>
      <p>Start today and let your medical talents shine!</p>
    </div>

    <Box
      marginTop={8}
      display={'flex'}
      gap={4}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Button
        component={RouterLink}
        to="/signup"
        variant="contained"
        color="secondary"
        size="large"
        sx={{
          minWidth: '140px',
        }}
      >
        Sign up
      </Button>
      <Box
        sx={{
          whiteSpace: 'nowrap',
        }}
      >
        - or -
      </Box>
      <Button
        component={RouterLink}
        to="/login"
        variant="contained"
        size="large"
        sx={{
          minWidth: '140px',
        }}
      >
        Log in
      </Button>
    </Box>
  </Box>
);

export default HomeViewAnonymoutContent;
