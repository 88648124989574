import { Box, Button, Link, Typography } from '@mui/material';
import { ProfileDTO } from 'generated/profile';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface HomeViewProfileEditContentProps {
  profile: ProfileDTO;
}

const HomeViewProfileEditContent: FC<HomeViewProfileEditContentProps> = ({
  profile,
}) => (
  <>
    <Typography variant="h5" mb={2} fontWeight="600">
      {profile.personalDetails?.forename
        ? ` Hi ${profile.personalDetails?.forename},`
        : 'Hello,'}
    </Typography>
    <div>
      <p>
        You can now continue your registration process at your leisure and
        don&apos;t worry, if you do not have everything to hand, you can save
        and return to it later
      </p>
      <p>
        Please do read the helpful information in each section to ensure you are
        uploading the correct information.
      </p>
      <p>
        Our team will review each stage and are available for any questions or
        queries on{' '}
        <Link fontWeight={'bold'} href="tel://+441254693067">
          01254693067
        </Link>{' '}
        or&nbsp;
        <Link fontWeight={'bold'} href="mailto:compliance@cobenhealthcare.com">
          compliance@cobenhealthcare.com
        </Link>
      </p>
    </div>
    <Box display={'flex'} justifyContent={'center'} mt={4}>
      <Button
        component={RouterLink}
        to="/profile/PERSONAL"
        variant="contained"
        color="secondary"
        size="large"
        sx={{
          minWidth: '140px',
        }}
      >
        Edit your profile
      </Button>
    </Box>
  </>
);

export default HomeViewProfileEditContent;
