import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { FC } from 'react';
import { Reference } from './ReferenceCardContainer';

interface ReferenceProps {
  defaultValues?: Reference;
  onClick: () => void;
}

const ReferenceCard: FC<ReferenceProps> = ({ defaultValues = {}, onClick }) => {
  const renderAddButton = !defaultValues.reference_name;

  const renderHiddenFields = () => {
    return Object.entries(defaultValues).map(([name, value]) => {
      return (
        <input type="hidden" name={name} value={value as string[]} key={name} />
      );
    });
  };

  return (
    <Button
      variant="text"
      onClick={onClick}
      sx={{
        padding: 0,
        margin: 0,
        textAlign: 'left',
        alignSelf: renderAddButton ? 'center' : 'stretch',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          padding: 2,
          boxShadow: '0 0px 2px rgba(0,0,0,0.3)',
          width: '100%',
        }}
        bgcolor={'#F5F5F5'}
        borderRadius={1}
      >
        {renderAddButton ? (
          <Typography
            variant="body1"
            textAlign={'center'}
            justifyContent={'center'}
            display={'flex'}
            alignItems={'center'}
            gap={1}
          >
            <ControlPointIcon sx={{ opacity: 0.7 }} /> Add reference
          </Typography>
        ) : (
          <>
            <Typography variant="body1" fontWeight={'bold'} component={'span'}>
              {defaultValues?.reference_name}{' '}
              {defaultValues?.reference_phone ? (
                <Typography variant="body2" component={'span'}>
                  ({defaultValues?.reference_phone})
                </Typography>
              ) : (
                ''
              )}
            </Typography>

            <Typography variant="body2">
              {defaultValues?.reference_position} @{' '}
              {defaultValues?.reference_organisation}
            </Typography>

            {renderHiddenFields()}
          </>
        )}
      </Box>
    </Button>
  );
};

export { ReferenceCard };
