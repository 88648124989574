import { useContext, useEffect, useState } from 'react';
import { FormContext } from './formContext';

import { Alert, Portal, Snackbar } from '@mui/material';
import Slide from '@mui/material/Slide';

interface FormErrorMessageProps {
  render?: (error: string) => React.ReactNode;
}

const FormErrorMessage = ({ render }: FormErrorMessageProps) => {
  const { submissionError } = useContext(FormContext);
  const [error, setError] = useState<string | undefined>(
    submissionError?.message,
  );

  useEffect(() => {
    if (submissionError) {
      setError(submissionError.message);
    }
  }, [submissionError]);

  if (render) {
    return <>{render(submissionError?.message || '')}</>;
  }

  return (
    <Portal>
      <Snackbar
        open={!!submissionError}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={Slide}
      >
        <Alert severity="error" elevation={6} variant="filled">
          {error}
        </Alert>
      </Snackbar>
    </Portal>
  );
};

export { FormErrorMessage };
