import { useAuth } from 'hooks/useAuth';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ForgottenPasswordComplete } from 'views/ForgottenPassword/ForgottenPasswordComplete';
import ForgottenPasswordView from 'views/ForgottenPassword/ForgottenPasswordView';

const ForgottenPasswordContainer = () => {
  const [email, setEmail] = useState<string | undefined>();
  const { resetPassword, sendPasswordResetCode } = useAuth();
  const navigate = useNavigate();
  const [isProcessComplete, setIsProcessComplete] = useState(false);

  const handleOnResetPassword = async (data: FormData) => {
    const dataObj = Object.fromEntries(data.entries());

    return resetPassword(
      email!,
      dataObj.password as string,
      dataObj.code as string,
    ).then(() => {
      console.log('Password reset!');
      setIsProcessComplete(true);
    });
  };

  const handleOnSendCode = async (data: FormData) => {
    const { email } = Object.fromEntries(data.entries());
    return sendPasswordResetCode(email as string).then(() => {
      setEmail(email as string);
      return true;
    });
  };

  return isProcessComplete ? (
    <ForgottenPasswordComplete />
  ) : (
    <ForgottenPasswordView
      hasEmail={!!email}
      onResetPassword={handleOnResetPassword}
      onSendCode={handleOnSendCode}
    />
  );
};

export { ForgottenPasswordContainer };
