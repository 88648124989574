import { AccountCircle, Logout } from '@mui/icons-material';
import {
  Box,
  BoxProps,
  Container,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from '@mui/material';
import { ReactComponent as Logo } from 'components/assets/logo.svg';
import TalkToUs from './TalkToUs';
import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { FC, useState } from 'react';

interface HeaderProps extends BoxProps {
  hasUserMenu?: boolean;
  onLogout?: () => void;
}

const Header: FC<HeaderProps> = ({ hasUserMenu, onLogout, sx, ...rest }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    handleClose();
    onLogout && onLogout();
  };

  return (
    <>
      <Container>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Link component={RouterLink} to="/">
            <Box
              sx={{
                fontSize: { xs: 50, sm: 80 },
              }}
              display={'flex'}
              alignItems={'center'}
            >
              <Logo height="1em" />
            </Box>
          </Link>
          <Box>
            {hasUserMenu && (
              <IconButton onClick={handleClick} size="large">
                <AccountCircle />
              </IconButton>
            )}{' '}
          </Box>
        </Box>
      </Container>
      <Box width={'100%'} sx={{ backgroundColor: 'primary.main' }}>
        <Container>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <TalkToUs />
          </Box>
        </Container>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default Header;
