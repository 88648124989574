import { Box, Button, Link, Typography } from '@mui/material';
import { FC } from 'react';

interface LoggedInContentNoProfileProps {
  onGetStarted: () => void;
}

const LoggedInContentNoProfile: FC<LoggedInContentNoProfileProps> = ({
  onGetStarted,
}) => (
  <>
    <Typography variant="h5" mb={2} fontWeight="600">
      Congratulations,
      <br />
      you have successfully created your account.
    </Typography>
    <div>
      <p>
        You can now start our registration process at your leisure and
        don&apos;t worry, if you do not have everything to hand, you can save
        and return to it later
      </p>
      <p>
        Please do read the helpful information in each section to ensure you are
        uploading the correct information.
      </p>
      <p>
        Our team will review each stage and are available for any questions or
        queries on{' '}
        <Link fontWeight={'bold'} href="tel://+441254693067">
          01254693067
        </Link>{' '}
        or&nbsp;
        <Link fontWeight={'bold'} href="mailto:compliance@cobenhealthcare.com">
          compliance@cobenhealthcare.com
        </Link>
      </p>
    </div>
    <Box display={'flex'} justifyContent={'center'} mt={4}>
      <Button
        variant="contained"
        size="large"
        color="secondary"
        onClick={onGetStarted}
        sx={{
          minWidth: '140px',
        }}
      >
        Get Started
      </Button>
    </Box>
  </>
);

export default LoggedInContentNoProfile;
