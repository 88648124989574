import React from 'react';
import ReactDOM from 'react-dom/client';
import 'style/base.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { theme } from 'components/constants/theme';
import { AuthProvider } from 'contexts/AuthContext';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ConfigProvider } from 'contexts/ConfigContext';
import { ApiClientsProvider } from 'contexts/ApiClientsContext';
import { MediaContext, MediaProvider } from 'contexts/MediaContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const appTheme = createTheme(theme);

root.render(
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <ThemeProvider theme={appTheme}>
      <ConfigProvider>
        <AuthProvider>
          <ApiClientsProvider>
            <MediaProvider>
              <CssBaseline />
              <App />
            </MediaProvider>
          </ApiClientsProvider>
        </AuthProvider>
      </ConfigProvider>
    </ThemeProvider>
  </LocalizationProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
