import React, { useCallback } from 'react';
import {
  Box,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  ORDERED_ONBOARDING_STEPS,
  registrationSteps,
} from './constants/registrationSteps';
import { Link } from 'react-router-dom';
import { OnboardingStep } from './types/onboarding';

export interface ProfileStepperProps {
  activeStep: number;
  hasValidPersonalDetails?: boolean;
  hasValidProfessionalDetails?: boolean;
  hasValidDocuments?: boolean;
  hasValidReferences?: boolean;
  orientation?: 'vertical' | 'horizontal';
}

interface StepIconProps {
  icon: React.ReactNode;
  active: boolean;
  disabled: boolean;
  hasErrors?: boolean;
}

const StepIconComponent: React.FC<StepIconProps> = ({
  icon,
  active,
  disabled,
  hasErrors,
}) => {
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: active
          ? theme.palette.secondary.main
          : disabled
          ? 'grey.300'
          : 'primary.main',
        color: 'common.white',
        borderColor: active ? 'primary.main' : 'text.disabled',
        border: `2px solid`,
        height: '3rem',
        width: '3rem',
        padding: '0.5rem',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
          height: '2rem',
          width: '2rem',
        },
      })}
    >
      {hasErrors && (
        <Box
          position={'absolute'}
          top={0}
          right={0}
          width={'14px'}
          height={'14px'}
          borderRadius={'14px'}
          border={'2px solid white'}
          bgcolor={'red'}
        />
      )}
      {icon}
    </Box>
  );
};

const ProfileStepper: React.FC<ProfileStepperProps> = ({
  activeStep,
  hasValidPersonalDetails,
  hasValidProfessionalDetails,
  hasValidDocuments,
  hasValidReferences,
  orientation = 'horizontal',
}) => {
  const matches = useMediaQuery('(max-width:600px)');

  const checkStepErrors = useCallback(
    (stepId: OnboardingStep['id']) => {
      switch (stepId) {
        case 'PERSONAL':
          return (
            !hasValidPersonalDetails &&
            (hasValidProfessionalDetails || hasValidReferences)
          );
        case 'PROFESSIONAL':
          return !hasValidProfessionalDetails && hasValidReferences;
        case 'REFERENCES':
          return hasValidDocuments && !hasValidReferences;

        default:
          return false;
      }
    },
    [hasValidDocuments, hasValidReferences],
  );

  const checkEnabledState = useCallback(
    (stepId: OnboardingStep['id']) => {
      switch (stepId) {
        case 'PROFESSIONAL':
        case 'PERSONAL':
        case 'REFERENCES':
          return true;

        case 'DOCUMENTS':
          return hasValidPersonalDetails && hasValidProfessionalDetails;

        case 'DECLARATION':
          return (
            hasValidPersonalDetails &&
            hasValidProfessionalDetails &&
            hasValidDocuments &&
            hasValidReferences
          );
        default:
          return false;
      }
    },
    [
      hasValidDocuments,
      hasValidPersonalDetails,
      hasValidProfessionalDetails,
      hasValidReferences,
    ],
  );

  return (
    <Stepper
      alternativeLabel={orientation !== 'vertical'}
      activeStep={activeStep}
      nonLinear
      connector={
        <StepConnector
          sx={
            orientation === 'vertical'
              ? { display: 'none' }
              : {
                  left: 'calc(-50% + 24px)',
                  right: 'calc(50% + 24px)',
                  top: {
                    xs: '16px',
                    sm: '24px',
                  },
                }
          }
        />
      }
      orientation={orientation}
    >
      {ORDERED_ONBOARDING_STEPS.map((stepId) => registrationSteps[stepId]).map(
        (step, stepIdx) => {
          const hasErrors = checkStepErrors(step.id);
          const isEnabled = checkEnabledState(step.id);

          return (
            <Step key={step.label} disabled={!isEnabled}>
              <Link
                to={isEnabled ? `/profile/${step.id}` : ''}
                style={{
                  textDecoration: 'none',
                }}
              >
                <StepLabel
                  StepIconComponent={({ active }) => (
                    <StepIconComponent
                      active={active}
                      disabled={!isEnabled}
                      icon={<step.icon />}
                      hasErrors={hasErrors}
                    />
                  )}
                >
                  {!matches && (
                    <Typography
                      variant="body2"
                      fontWeight={stepIdx === activeStep ? 'bold' : 100}
                    >
                      {step.label}
                    </Typography>
                  )}
                </StepLabel>
              </Link>
            </Step>
          );
        },
      )}
    </Stepper>
  );
};

export default ProfileStepper;
