import {
  Box,
  Typography,
  RadioGroup,
  FormControl,
  FormControlProps,
  FormHelperText,
  FormLabel,
} from '@mui/material';
import { FormContext } from 'components/form/formContext';
import { OptionBox } from 'components/optionBox';

import { FC, useContext, useEffect, useRef, useState } from 'react';

type option = {
  value: string;
  label: string;
};

interface OptionGroupProps extends FormControlProps {
  options: option[];
  defaultValue?: string;
  title?: string;
  description?: string;
  name: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
  row?: boolean;
}

const OptionGroup: FC<OptionGroupProps> = ({
  options,
  onChange,
  children,
  defaultValue,
  title,
  description,
  name,
  required,
  row = false,
  ...rest
}) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const { isSubmissionAttempted } = useContext(FormContext);
  const groupRef = useRef<HTMLFieldSetElement>(null);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateErrorMessage();
    onChange && onChange(event);
  };
  const showError = isSubmissionAttempted && !!errorMessage;

  const updateErrorMessage = () => {
    const inputs = Array.from(
      groupRef.current?.querySelectorAll('input') || [],
    );

    setErrorMessage(
      inputs.find((input) => input.validationMessage)?.validationMessage || '',
    );
  };

  useEffect(() => {
    updateErrorMessage();
  }, []);

  return (
    <FormControl {...rest}>
      <Box
        component={'fieldset'}
        borderRadius={1}
        margin={0}
        border={'1px solid #cbcbcb'}
      >
        {title && (
          <legend>
            <FormLabel error={showError} required={required}>
              {title}
            </FormLabel>
          </legend>
        )}
        {description && (
          <FormHelperText component={'div'}>
            <Typography variant="body2">{description}</Typography>
          </FormHelperText>
        )}

        <RadioGroup
          onChange={handleOnChange}
          defaultValue={defaultValue}
          row={row}
          ref={groupRef}
        >
          {options.map((option) => (
            <OptionBox
              key={option.label}
              label={option.label}
              value={option.value}
              name={name}
              required={required}
            />
          ))}
        </RadioGroup>
        {children}

        {showError && (
          <FormHelperText component={'div'} error={showError}>
            <Typography variant="body2">{errorMessage}</Typography>
          </FormHelperText>
        )}
      </Box>
    </FormControl>
  );
};

export { OptionGroup };
export type { OptionGroupProps, option };
