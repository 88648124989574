import Button, { ButtonProps } from '@mui/material/Button';
import { FormContext } from 'components/form/formContext';
import { useContext } from 'react';

interface CancelButtonProps extends ButtonProps {
  onCancel?: () => void;
  children?: React.ReactNode;
}

const CancelButton = ({ children, onCancel, ...rest }: CancelButtonProps) => {
  const { reset } = useContext(FormContext);

  const handleCancel = () => {
    reset();
    onCancel?.();
  };

  return (
    <Button
      type="button"
      variant="outlined"
      onClick={handleCancel}
      sx={{ minWidth: '120px' }}
      {...rest}
    >
      {children ? children : <>Cancel</>}
    </Button>
  );
};

export { CancelButton };
