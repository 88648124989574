import useApiClients from 'hooks/useApiClients';
import { FC, ReactNode, createContext } from 'react';
import axios from 'axios';

export type UploadedItem = { profileId: string; category: string; file: File };

export type MediaContextType = {
  uploadMedia: (
    profileId: string,
    category: string,
    file: File,
    onProgress?: (progress: number) => void,
  ) => Promise<UploadedItem>;
  downloadMedia: (
    profileId: string,
    category: string,
    filename: string,
    onProgress?: (progress: number) => void,
  ) => Promise<any>;
};

const MediaContext = createContext<MediaContextType>({
  uploadMedia: () => Promise.reject(new Error('MediaProvider not initialized')),
  downloadMedia: () =>
    Promise.reject(new Error('MediaProvider not initialized')),
});

interface MediaProviderProps {
  children: ReactNode;
}

const MediaProvider: FC<MediaProviderProps> = ({ children }) => {
  const { mediaClient } = useApiClients();

  const uploadMedia = (
    profileId: string,
    category: string,
    file: File,
    onProgress?: (progress: number) => void,
  ) =>
    mediaClient?.candidate
      .getSelfUploadUrl(profileId, category, file.name)
      .then(({ url }) => {
        return axios.put(url, file, {
          onUploadProgress: (progressEvent) => {
            if (onProgress && progressEvent.total) {
              onProgress((progressEvent.loaded / progressEvent.total) * 100);
            }
            if (onProgress && !progressEvent.total) {
              onProgress(-1);
            }
          },
        });
      })
      .then(() => {
        return { profileId, file, category };
      }) || Promise.reject(new Error('MediaProvider not initialized'));

  const downloadMedia = (
    profileId: string,
    category: string,
    filename: string,
    onProgress?: (progress: number) => void,
  ) =>
    mediaClient?.candidate
      .getSelfDownloadUrl(profileId, category, filename)
      .then(({ url }) => {
        window.open(url, '_blank');
        // return axios.get(url, {
        //   onDownloadProgress: (progressEvent) => {
        //     if (onProgress && progressEvent.total) {
        //       onProgress(progressEvent.loaded / progressEvent.total);
        //     }
        //     if (onProgress && !progressEvent.total) {
        //       onProgress(-1);
        //     }
        //   },
        // });
      }) || Promise.reject(new Error('MediaProvider not initialized'));

  return (
    <MediaContext.Provider
      value={{
        uploadMedia,
        downloadMedia,
      }}
    >
      {children}
    </MediaContext.Provider>
  );
};

export { MediaProvider, MediaContext };
