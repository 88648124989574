import { Box } from '@mui/material';

import {
  Reference,
  ReferenceCardContainer,
} from 'components/ReferenceCardContainer';
import { Form } from 'components/form';
import { FormErrorMessage } from 'components/form/formErrorMessage';
import { SectionCTAs } from 'components/sectionCTAs';
import { ProfileDTO } from 'generated/profile';
import useProfile from 'hooks/useProfile';
import { FC, useRef, useState } from 'react';
import { mapProfileToFormData } from 'helpers/profile';

interface ReferencesControlProps {
  onSubmit: (data: { [key: string]: string | string[] }) => Promise<ProfileDTO>;
  profile?: ProfileDTO;
}

type References = Record<keyof Reference, string[]>;

const parseReferences = (value: References) =>
  value.reference_name.map(
    // eslint-disable-next-line camelcase
    (reference_name: string, index: number) => ({
      // eslint-disable-next-line camelcase
      reference_name,
      reference_id: value?.reference_id?.[index],
      reference_organisation: value?.reference_organisation?.[index],
      reference_position: value?.reference_position?.[index],
      reference_phone: value?.reference_phone?.[index],
      reference_grade: value?.reference_grade?.[index],
      reference_email: value?.reference_email?.[index],
      reference_work_email: value?.reference_work_email?.[index],
      reference_worked_from: value?.reference_worked_from?.[index],
      reference_worked_to: value?.reference_worked_to?.[index],
      reference_grade_worked_at: value?.reference_grade_worked_at?.[index],
    }),
  );

const ReferencesControl: FC<ReferencesControlProps> = ({
  onSubmit,
  profile,
}) => {
  const { hasValidProfessionalDetails, hasValidPersonalDetails } = useProfile();
  const formRef = useRef<HTMLFormElement>(null);

  const [references, setReferences] = useState<Reference[]>(
    parseReferences(mapProfileToFormData(profile!)),
  );

  const submitForm = () => {
    window.requestAnimationFrame(() => {
      formRef.current!.requestSubmit();
    });
  };

  const handleOnAdd = (data: any) => {
    setReferences([...references, data]);
    submitForm();
  };

  const handleOnUpdate = (index: number) => (data: any) => {
    setReferences(references.map((value, i) => (i === index ? data : value)));
    submitForm();
  };

  const handleOnRemove = (index: number) => () => {
    setReferences(references.filter((_, i) => i !== index));
    submitForm();
  };
  return (
    <Form
      ref={formRef}
      onSubmit={(formData: FormData) => {
        const data: { [k: string]: string[] } = {};
        for (const entry of formData.entries()) {
          const [key, profile] = entry as [string, string];
          if (!data[key]) {
            data[key] = [];
          }
          data[key].push(profile);
        }

        return onSubmit(data);
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
        }}
      >
        {references?.map((reference: Reference, index: number) => (
          <ReferenceCardContainer
            key={reference.reference_name}
            values={reference}
            onUpdate={handleOnUpdate(index)}
            onRemove={handleOnRemove(index)}
          />
        ))}
        <ReferenceCardContainer
          key={references?.length}
          onAdd={handleOnAdd}
          values={{}}
        />

        <FormErrorMessage />
        <SectionCTAs
          previousUrl="/profile/PROFESSIONAL"
          nextUrl={
            hasValidProfessionalDetails && hasValidPersonalDetails
              ? '/profile/DOCUMENTS'
              : ''
          }
        />
      </Box>
    </Form>
  );
};

export default ReferencesControl;
