import { VisibilityOff, Visibility } from '@mui/icons-material';
import { InputAdornment, IconButton, Box } from '@mui/material';
import { InputBox, InputBoxProps } from 'components/inputBox';
import { FC, ReactNode, useMemo, useState } from 'react';
import { CheckList } from './checkList';
import { combineRegExp } from 'components/constants/passwordValidationRegExp';

type ValidationRule = {
  pattern: RegExp;
  label: ReactNode;
};

type PasswordProps = InputBoxProps & {
  validationRules?: ValidationRule[];
};

const Password: FC<PasswordProps> = ({
  validationRules,
  onChange,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const el = e.target as HTMLInputElement;
    setPassword(el.value);
    onChange && onChange(e);
  };

  const handleClickShowPassword = () => {
    setShowPassword((state) => !state);
  };

  const passwordValidRegExp = useMemo(() => {
    return validationRules?.length
      ? combineRegExp(validationRules.map((rule) => rule.pattern))
      : undefined;
  }, [validationRules]);

  return (
    <div>
      <InputBox
        type={showPassword ? 'text' : 'password'}
        onChange={handleOnChange}
        inputProps={{
          pattern: passwordValidRegExp?.source,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...rest}
      />

      {validationRules && (
        <CheckList password={password} rules={validationRules} />
      )}
    </div>
  );
};

export { Password };
export type { PasswordProps, ValidationRule };
