import { Box, Typography } from '@mui/material';
import { Attachment } from 'components/attachment/Attachment';
import { CheckBox } from 'components/checkBox';
import { Form } from 'components/form';
import { SubmitButton } from 'components/submitButton';
import { FC } from 'react';

interface SubmissionControlProps {
  profile: any;
  uploadProgress: number;
  handleUploadSignedDeclaration: (file: File) => void;
  handleSubmitForVerification: () => Promise<any>;
}

const SubmissionControl: FC<SubmissionControlProps> = ({
  profile,
  uploadProgress,
  handleUploadSignedDeclaration,
  handleSubmitForVerification,
}) => {
  return (
    <Form onSubmit={handleSubmitForVerification}>
      <Box display="flex" flexDirection="column" gap={4} key="empty">
        <Typography variant="h5">Final Declaration</Typography>
        <CheckBox
          label="I confirm that all the information provided to Coben Healthcare is correct to the best of my knowledge and belief. I understand that a false declaration may lead to refusal of this application. If, while I am working with Coben Healthcare, any of the information provided changes, I agree to notify Coben Healthcare in writing immediately."
          name="final_declaration"
          required
        />
        <CheckBox
          label="I understand and agree to Coben Healthcare disclosing this information to their clients for the purpose of finding me assignments."
          name="disclosure_to_clients"
          required
        />
        <CheckBox
          label="I have read, understood, and accept the information contained within the staff handbook."
          name="accept_staff_handbook"
          required
        />
        <CheckBox
          label="I have read and agree to adhere to the Coben Healthcare Terms of Engagement."
          name="accept_terms_of_engagement"
          required
        />
        {profile.professionalDetails?.jobTitle === 'phlebotomist' && (
          <CheckBox
            label="I have read and will adhere to the Coben Healthcare Uniform Policy."
            name="adhere_uniform_policy"
            required
          />
        )}
        <CheckBox
          label="I have read and agree to Coben Healthcare Privacy Policy."
          name="accept_privacy_policy"
          required
        />
        <Typography variant="body1">
          Please upload a signed declaration to submit your profile for
          verification.
        </Typography>

        <Attachment
          label="Signed declaration"
          onFileSelected={handleUploadSignedDeclaration}
          onRemove={() => {}}
          progress={uploadProgress}
          filename={profile?.declaration?.signedDeclaration}
          required
        />
        <SubmitButton fullWidth sx={{ marginTop: 2 }}>
          Submit for verification
        </SubmitButton>
      </Box>
    </Form>
  );
};

export default SubmissionControl;
