import { Link, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import { FC, useContext } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { FormContext } from 'components/form/formContext';
import { SubmitButton } from 'components/submitButton';
import { CancelButton } from 'components/cancelButton';

interface SectionCTAsProps {
  previousUrl?: string;
  nextUrl?: string;
}

const SectionCTAs: FC<SectionCTAsProps> = ({ previousUrl, nextUrl }) => {
  const theme = useTheme();
  const { isDirty } = useContext(FormContext);

  return (
    <Box
      display="flex"
      gap={2}
      justifyContent="space-between"
      paddingY={2}
      paddingX={4}
      bgcolor={theme.palette.grey[100]}
      borderRadius={1}
    >
      {isDirty ? (
        <>
          <CancelButton />
          <SubmitButton />
        </>
      ) : (
        <>
          {previousUrl ? (
            <Link
              component={RouterLink}
              to={previousUrl}
              display={'flex'}
              alignItems={'center'}
              flexBasis={0}
            >
              <ChevronLeftIcon /> Previous
            </Link>
          ) : (
            <Box flexBasis={0} />
          )}
          {nextUrl ? (
            <Link
              component={RouterLink}
              to={nextUrl}
              display={'flex'}
              alignItems={'center'}
              flexBasis={0}
            >
              Next <ChevronRightIcon />
            </Link>
          ) : (
            <Box flexBasis={0} />
          )}
        </>
      )}
    </Box>
  );
};

export { SectionCTAs };
export type { SectionCTAsProps };
