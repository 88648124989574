import { Button, Link, Typography } from '@mui/material';
import { ProfileDTO } from 'generated/profile';
import { FC } from 'react';

interface HomeViewVerifiedContentProps {
  profile: ProfileDTO;
  handleUpdateProfile: () => void;
}

const HomeViewVerifiedContent: FC<HomeViewVerifiedContentProps> = ({
  profile,
  handleUpdateProfile,
}) => (
  <>
    <Typography variant="h5" mb={2} fontWeight="600">
      {profile.personalDetails?.forename
        ? `Hi ${profile.personalDetails?.forename},`
        : 'Hello,'}
    </Typography>
    <div>
      <p>
        Congratulations! Your profile verification is now complete and
        successful.
      </p>
      <p>
        Our compliance team has reviewed your information, and everything is in
        order. If they need any further information in the future, they&apos;ll
        be in touch with you directly.
      </p>
      <p>
        If you have any questions or need assistance, feel free to contact our
        team at:
      </p>
      <p>
        Phone:{' '}
        <Link fontWeight={'bold'} href="tel://+441254693067">
          01254 693067
        </Link>
      </p>
      <p>
        Email:{' '}
        <Link fontWeight={'bold'} href="mailto:compliance@cobenhealthcare.com">
          compliance@cobenhealthcare.com
        </Link>
      </p>
      <p>
        Thank you for being a part of our community. If you ever have any
        significant changes to your profile, such as new certifications or
        qualifications, you can update your information at your convenience.
      </p>
      <Button variant="contained" color="primary" onClick={handleUpdateProfile}>
        Update Profile
      </Button>
    </div>
  </>
);

export default HomeViewVerifiedContent;
