import {
  Box,
  Card,
  CircularProgress,
  Container,
  Typography,
  useTheme,
} from '@mui/material';
import Footer from 'components/Footer';
import Layout from 'components/Layout';
import ProfileStepper from 'components/ProfileStepper';
import {
  ORDERED_ONBOARDING_STEPS,
  getStep,
} from 'components/constants/registrationSteps';
import { OnboardingStepId } from 'components/types/onboarding';
import { Profile } from 'components/types/user';
import { VerificationStatus } from 'components/types/verification';

import React, { FC, ReactNode } from 'react';

interface ProfileViewProps {
  profile: Profile;
  verificationStatus?: VerificationStatus;
  header: ReactNode;
  step: OnboardingStepId;
  controls: React.ReactNode[];
  hasValidPersonalDetails?: boolean;
  hasValidProfessionalDetails?: boolean;
  hasValidDocuments?: boolean;
  hasValidReferences?: boolean;
}

const ProfileView: FC<ProfileViewProps> = ({
  profile,
  header,
  verificationStatus,
  controls,
  step,
  hasValidPersonalDetails,
  hasValidProfessionalDetails,
  hasValidDocuments,
  hasValidReferences,
}) => {
  const { label, description, icon: Icon } = getStep(step);
  const theme = useTheme();

  return (
    <Layout header={header} footer={<Footer />}>
      <Container
        sx={{
          minHeight: '100%',
          backgroundColor: 'rgba(255,255,255,0.8)',
          backdropFilter: 'blur(10px) brightness(1.3)',
          paddingY: 4,
          boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.2)',
        }}
      >
        <ProfileStepper
          activeStep={ORDERED_ONBOARDING_STEPS.indexOf(step)}
          hasValidPersonalDetails={hasValidPersonalDetails}
          hasValidProfessionalDetails={hasValidProfessionalDetails}
          hasValidDocuments={hasValidDocuments}
          hasValidReferences={hasValidReferences}
        />
        <Box
          padding={{
            xs: 1,
            sm: 4,
          }}
        >
          <Typography
            variant="h3"
            component="h2"
            marginBottom={4}
            color={theme.palette.primary.main}
            fontWeight={100}
          >
            {label}
          </Typography>
          <Box
            display={'flex'}
            flexDirection={{
              xs: 'column',
              md: 'row',
            }}
            gap={4}
          >
            <Box display={'flex'} flexDirection={'column'} flexGrow={1}>
              <Typography
                variant="body1"
                component="div"
                color={theme.palette.grey[600]}
                flexShrink={0}
              >
                {description}
              </Typography>

              <Box
                display={{
                  xs: 'none',
                  md: 'flex',
                }}
                flexGrow={1}
                alignSelf="center"
              >
                <Icon
                  sx={{
                    fontSize: '20rem',
                    color: theme.palette.grey[200],
                    justifySelf: 'center',
                    alignSelf: 'center',
                  }}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              sx={{
                gap: 1,
                flexShrink: 0,
                flexBasis: {
                  xs: 'auto',
                  md: '520px',
                },
              }}
            >
              {profile === undefined && (
                <Card
                  sx={{
                    padding: {
                      xs: 1,
                      sm: 4,
                    },
                    marginBottom: 1,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  elevation={3}
                >
                  <CircularProgress />
                </Card>
              )}
              {profile !== undefined &&
                controls.map((control, index) => (
                  <Card
                    sx={{
                      padding: {
                        xs: 1,
                        sm: 4,
                      },
                      marginBottom: 1,
                    }}
                    elevation={3}
                    key={index}
                  >
                    {control}
                  </Card>
                ))}
            </Box>
          </Box>
        </Box>
      </Container>
    </Layout>
  );
};

export default ProfileView;
