/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CommentDTO = {
    comment: string;
    createdBy: string;
    createdAt: string;
    type?: CommentDTO.type;
};

export namespace CommentDTO {

    export enum type {
        GENERAL = 'GENERAL',
        ACTION_REQUIRED = 'ACTION_REQUIRED',
    }


}

