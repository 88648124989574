import { Box, Container, Paper, Typography } from '@mui/material';
import Footer from 'components/Footer';
import Layout from 'components/Layout';

import { FC } from 'react';

interface HomeViewProps {
  header: React.ReactNode;
  children?: React.ReactNode;
}

const HomeView: FC<HomeViewProps> = ({ header, children }) => {
  return (
    <Layout header={header} footer={<Footer />}>
      <Container
        sx={{
          height: '100%',
          backgroundColor: 'rgba(255,255,255,0.8)',
          backdropFilter: 'blur(10px) brightness(1.3)',
          boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.2)',
          overflow: 'auto',
        }}
      >
        <Box
          padding={{
            xs: 2,
            sm: 8,
          }}
          height={'100%'}
        >
          <Box
            display="flex"
            flexDirection={{
              xs: 'column',
              md: 'row',
            }}
            gap={4}
          >
            {children}
          </Box>
        </Box>
      </Container>
    </Layout>
  );
};

export default HomeView;
