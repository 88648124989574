/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PersonalDetailsDTO = {
    forename?: string;
    surname?: string;
    address?: string;
    mobileNumber?: string;
    homeNumber?: string;
    dateOfBirth?: string;
    nhsEmailAddress?: string;
    nationality?: PersonalDetailsDTO.nationality;
    biometricImmigrationDocumentIssued?: boolean;
    payType?: PersonalDetailsDTO.payType;
};

export namespace PersonalDetailsDTO {

    export enum nationality {
        BRITISH = 'BRITISH',
        EEA = 'EEA',
        OTHER = 'OTHER',
    }

    export enum payType {
        PAYE = 'PAYE',
        LTD = 'LTD',
        SOLE_TRADER = 'SOLE_TRADER',
    }


}

