import { Typography, List, ListItem } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const listItems = [
  'We have been supplying clinicians into Primary Care settings for over a decade',
  'We are a GMC designated body and ISO 9001 Certified',
  'We are partnered with MEDSU to assist Drs with revalidation',
  'We are partnered with Healthier Business which offers free online training and health screening',
  'You can contact our team 24 hours a day',
  'We have our own Clinical Lead to offer support and guidance',
  'We are preferred supplier for some of the largest OOH services in the country',
  'We also have this App for an quick and easy registration process',
];

const whyJoinCoben = (
  <List>
    {listItems.map((item, index) => (
      <ListItem key={index} alignItems="flex-start" sx={{ gap: 2 }}>
        <FiberManualRecordIcon sx={{ fontSize: '10px', marginTop: '0.5em' }} />
        <Typography>{item}</Typography>
      </ListItem>
    ))}
  </List>
);

export { whyJoinCoben };
