/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { VerificationEntityIdDTO } from './VerificationEntityIdDTO';

/**
 * Comment request DTO. This is the DTO used to create a comment.
 * A comment is a message that is attached to a verification section.
 * It can be of type GENERAL or ACTION_REQUIRED. GENERAL comments are just messages.
 * ACTION_REQUIRED comments are messages that require the user to take action.
 * For example, if a user uploads a document that is not valid, the admin can create an ACTION_REQUIRED comment to ask
 * the user to upload a new document.
 */
export type CommentRequestDTO = {
    entityId: VerificationEntityIdDTO;
    /**
     * The comment message. This is the message that will be displayed to the user.
     */
    comment: string;
    /**
     * The type of the comment. GENERAL comments are just messages. ACTION_REQUIRED comments are messages
     * that require the user to take action. For example, if a user uploads a document that is not valid, the admin can
     * create an ACTION_REQUIRED comment to ask the user to upload a new document.
     */
    type: CommentRequestDTO.type;
};

export namespace CommentRequestDTO {

    /**
     * The type of the comment. GENERAL comments are just messages. ACTION_REQUIRED comments are messages
     * that require the user to take action. For example, if a user uploads a document that is not valid, the admin can
     * create an ACTION_REQUIRED comment to ask the user to upload a new document.
     */
    export enum type {
        GENERAL = 'GENERAL',
        ACTION_REQUIRED = 'ACTION_REQUIRED',
    }


}

