import { FC } from 'react';
import { Form } from './form';
import { InputBox } from './inputBox';
import { Box, Divider, Typography } from '@mui/material';
import { DateBox } from './dateBox';
import { theme } from './constants/theme';

interface ReferenceFormProps {
  defaultValues?: {
    reference_id?: string;
    reference_name?: string;
    reference_organisation?: string;
    reference_position?: string;
    reference_grade?: string;
    reference_email?: string;
    reference_phone?: string;
    reference_work_email?: string;
    reference_worked_from?: string;
    reference_worked_to?: string;
    reference_grade_worked_at?: string;
  };
  id?: string;
  onSubmit: (data: any) => Promise<void>;
}

const ReferenceForm: FC<ReferenceFormProps> = ({
  id,
  onSubmit,
  defaultValues = {},
}) => {
  return (
    <Form id={id} onSubmit={onSubmit}>
      <input
        type="hidden"
        name="reference_id"
        defaultValue={defaultValues.reference_id}
      />
      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <Typography variant="body2" color={theme.palette.grey[600]}>
          Please provide the contact information of a professional reference who
          can attest to your skills and qualifications. This should be someone
          who has worked closely with you and can speak to your work ethic and
          abilities.
        </Typography>
        <Divider sx={{ marginTop: 2 }} textAlign="left">
          <Typography variant="body2" color={'grey'}>
            Your referrer
          </Typography>
        </Divider>
        <InputBox
          label="Name"
          name="reference_name"
          required
          defaultValue={defaultValues.reference_name}
        />
        <InputBox
          label="Organisation"
          name="reference_organisation"
          required
          defaultValue={defaultValues.reference_organisation}
        />
        <InputBox
          label="Position"
          name="reference_position"
          required
          defaultValue={defaultValues.reference_position}
        />
        <InputBox
          label="Grade/Band/Specialism"
          name="reference_grade"
          required
          defaultValue={defaultValues.reference_grade}
        />
        <InputBox
          label="Email"
          name="reference_email"
          type="email"
          defaultValue={defaultValues.reference_email}
          required
        />
        <InputBox
          label="Phone"
          name="reference_phone"
          defaultValue={defaultValues.reference_phone}
          required
        />
        <InputBox
          label="Work Referee Email"
          name="reference_work_email"
          type="email"
          defaultValue={defaultValues.reference_work_email}
        />

        <Divider sx={{ marginTop: 2 }} textAlign="left">
          <Typography variant="body2" color={'grey'}>
            Your work
          </Typography>
        </Divider>
        <Box display={'flex'} gap={2} border={0}>
          <DateBox
            name="reference_worked_from"
            label="From"
            defaultValue={defaultValues.reference_worked_from}
            required
            fullWidth
          />

          <DateBox
            name="reference_worked_to"
            label="To"
            defaultValue={defaultValues.reference_worked_to}
            required
            fullWidth
          />
        </Box>
        <InputBox
          label="Grade and Speciality"
          name="reference_grade_worked_at"
          defaultValue={defaultValues.reference_grade_worked_at}
          required
        />
      </Box>
    </Form>
  );
};

export { ReferenceForm };
