import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import PostAddIcon from '@mui/icons-material/PostAdd';
import DrawIcon from '@mui/icons-material/Draw';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import { Box, Typography } from '@mui/material';
import { OnboardingStep, OnboardingStepId } from 'components/types/onboarding';

export const ORDERED_ONBOARDING_STEPS = [
  'PERSONAL',
  'PROFESSIONAL',
  'REFERENCES',
  'DOCUMENTS',
  'DECLARATION',
] as const;

const registrationSteps: Record<OnboardingStepId, OnboardingStep> = {
  PERSONAL: {
    id: 'PERSONAL',
    label: 'Personal details',
    icon: EmojiPeopleIcon,
    description: (
      <Box display="flex" flexDirection={'column'} gap={2}>
        <Typography variant="body1">Hey there!</Typography>
        <Typography variant="body1">
          We&apos;re excited to get to know you better!
        </Typography>
        <Typography variant="body1">
          To create your profile, we just need a little bit of information from
          you.
        </Typography>
        <Typography variant="body1">
          Don&apos;t worry, your privacy is super important to us, so rest
          assured that all the data you provide will be kept safe and
          confidential.
        </Typography>
        <Typography variant="body1">
          Fill out these fields to help us accurately identify and communicate
          with you.
        </Typography>
      </Box>
    ),
  },
  PROFESSIONAL: {
    id: 'PROFESSIONAL',
    label: 'Professional details',
    icon: LocalHospitalIcon,
    description: (
      <Box display="flex" flexDirection={'column'} gap={2}>
        <Typography variant="body1">
          In this step, we will gather information about your professional
          background and experience.
        </Typography>
        <Typography variant="body1">
          You will have the opportunity to provide details about your work
          history, skills, and any relevant certifications or licenses.
        </Typography>
        <Typography variant="body1">
          These details are crucial for us to understand your expertise and
          match you with suitable opportunities. We value the accuracy of this
          information and appreciate your effort in providing it.
        </Typography>
      </Box>
    ),
  },
  REFERENCES: {
    id: 'REFERENCES',
    label: 'References',
    icon: Diversity3Icon,
    description: (
      <Box display="flex" flexDirection={'column'} gap={2}>
        <Typography variant="body1">
          Our references are required to cover the last 3 years work history.
        </Typography>
        <Typography variant="body1">
          The referee must be senior to yourself and at least 1 must be
          clinical. All references must match your employment history within
          your CV.
        </Typography>
        <Typography variant="body1">
          If you have not worked at the same employment for the last 3 years
          this may equate to several references.
        </Typography>
        <Typography variant="body1">
          Other referees are needed to complete the 3-year period.
        </Typography>
        <Typography variant="body1">
          Additional referees can be provided by email where required.
        </Typography>
      </Box>
    ),
  },
  DOCUMENTS: {
    id: 'DOCUMENTS',
    label: 'Documents',
    icon: PostAddIcon,
    description: (
      <Box display="flex" flexDirection={'column'} gap={2}>
        <Typography variant="body1">
          Here, you have the option to attach relevant documents and files that
          support your profile.
        </Typography>
        <Typography variant="body1">
          This may include your resume, cover letter, certificates, or any other
          documents that highlight your achievements or qualifications.
        </Typography>
        <Typography variant="body1">
          Uploading these materials can greatly enhance your profile and
          increase your chances of success.
        </Typography>
        <Typography variant="body1">
          We ensure that your attachments will be handled securely and will only
          be used for the intended purpose.
        </Typography>
      </Box>
    ),
  },
  DECLARATION: {
    id: 'DECLARATION',
    label: 'Declaration',
    icon: DrawIcon,
    description: (
      <Box display="flex" flexDirection={'column'} gap={2}>
        <Typography variant="body1">
          As we reach the last step of the process, we want to make sure
          everything is as easy and straightforward as possible for you.
          We&apos;ll present you with a declaration form that simply summarizes
          all the information you&apos;ve provided so far. It&apos;s just a way
          for us to double-check that everything is accurate and up-to-date.
        </Typography>

        <Typography variant="body1">
          We totally understand that filling out forms can be a bit tedious, but
          please bear with us. Your privacy and security are of the utmost
          importance to us, and this step ensures that we have your consent and
          acknowledgement of the information you&apos;ve shared.
        </Typography>

        <Typography variant="body1">
          Once you get to the form, please take a moment to review it carefully.
          If everything looks good and you&apos;re satisfied with the details,
          we&apos;ll ask you to sign it. Think of it as a simple digital
          handshake, showing that you stand by the accuracy of the information
          in your profile.
        </Typography>

        <Typography variant="body1">
          If you have any questions or concerns along the way, don&apos;t
          hesitate to reach out to our friendly support team. We&apos;re here to
          help and make this process as smooth as possible for you. Thanks for
          your understanding and cooperation!
        </Typography>
      </Box>
    ),
  },
};

const getStep = (id: OnboardingStepId) => registrationSteps[id];

export { registrationSteps, getStep };
