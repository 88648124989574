import { createContext } from 'react';

interface FormContextProps {
  isDirty: boolean;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;

  submissionError: Error | null;
  setSubmissionError: React.Dispatch<React.SetStateAction<Error | null>>;

  isSubmissionAttempted: boolean;
  setIsSubmissionAttempted: React.Dispatch<React.SetStateAction<boolean>>;

  isSubmitting: boolean;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;

  submitted: boolean;
  setSubmitted: React.Dispatch<React.SetStateAction<boolean>>;

  reset: () => void;
}

const FormContext = createContext<FormContextProps>({
  isDirty: false,
  setIsDirty: () => {},

  submissionError: null,
  setSubmissionError: () => {},

  isSubmissionAttempted: false,
  setIsSubmissionAttempted: () => {},

  isSubmitting: false,
  setIsSubmitting: () => {},

  submitted: false,
  setSubmitted: () => {},

  reset: () => {},
});

export { FormContext };
