import { Box, FormLabel, Radio, RadioProps } from '@mui/material';

interface OptionBoxProps extends RadioProps {
  label: string;
  value: string;
  name: string;
  required?: boolean;
}
const OptionBox = ({
  label,
  value,
  required = false,
  ...rest
}: OptionBoxProps) => {
  return (
    <FormLabel
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Radio value={value} required={required} {...rest} />
      <Box lineHeight={1} component={'span'}>
        {label}
      </Box>
    </FormLabel>
  );
};

export { OptionBox };
