import { useAuth } from 'hooks/useAuth';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router';
import RegistrationView from 'views/RegistrationView';

export interface RegistrationError {
  email?: string | null;
  password?: string | null;
  other?: string | null;
}

const RegistrationContainer: FC = () => {
  const { loading, signup } = useAuth();
  const naigate = useNavigate();
  const [registrationError, setRegistrationError] =
    useState<RegistrationError | null>(null);

  const handleRegistrationSubmit = (email: string, password: string) => {
    return signup(email, password)
      .then((result) => {
        if (!result) {
          setRegistrationError({
            other: 'An unknown error occurred',
          });
          return;
        }
        naigate('/verify');
      })
      .catch((error) => {
        switch (error.code) {
          case 'UsernameExistsException':
            setRegistrationError({
              email: 'Email already in use',
            });
            break;
          case 'InvalidPasswordException':
            setRegistrationError({
              password: 'Password must be at least 8 characters',
            });
            break;
          default:
            setRegistrationError({
              other: 'An unknown error occurred',
            });
            break;
        }
        throw error;
      });
  };

  return (
    <RegistrationView
      registrationError={registrationError}
      loading={loading}
      onSubmit={handleRegistrationSubmit}
      onChange={() => {
        if (registrationError) {
          setRegistrationError(null);
        }
      }}
    />
  );
};

export default RegistrationContainer;
