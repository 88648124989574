import { FC, MouseEventHandler, useState } from 'react';
import UserView from './UserView';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Link,
  Snackbar,
  Typography,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Link as RouterLink } from 'react-router-dom';
import { Form } from 'components/form';
import { RegistrationError } from 'containers/RegistrationContainer';
import { InputBox } from 'components/inputBox';
import { CheckBox } from 'components/checkBox';
import { TermsAndConditions } from 'components/constants/termsAndConditions';
import { NewPassword } from 'components/newPassword';
import { FormErrorMessage } from 'components/form/formErrorMessage';

interface RegistrationViewProps {
  email?: string;
  registrationError?: RegistrationError | null;
  loading?: boolean;
  onChange: (name: string, value: string) => void;
  onSubmit: (email: string, password: string) => Promise<void>;
}

const RegistrationView: FC<RegistrationViewProps> = ({
  email,
  registrationError,
  loading,
  onSubmit,
  onChange,
}) => {
  const [displayModal, setDisplayModal] = useState(false);

  const handleSubmit = (formData: FormData) => {
    return onSubmit(
      formData.get('email') as string,
      formData.get('password') as string,
    );
  };

  const verifyEmail = (value: string) => registrationError?.email || '';
  const verifyPassword = (value: string) => registrationError?.password || '';

  const handleFieldChanged = (event: React.FormEvent<HTMLFormElement>) =>
    onChange('', '');

  const handleTermsAndConditionsClick: MouseEventHandler = (e) => {
    e.preventDefault();
    setDisplayModal(true);
  };

  return (
    <UserView loading={loading}>
      <Avatar
        className="avatar"
        sx={(theme) => ({ bgcolor: theme.palette.secondary.main })}
      >
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign up
      </Typography>
      <Form onSubmit={handleSubmit} onChange={handleFieldChanged}>
        <Box display={'flex'} flexDirection={'column'} gap={4}>
          <Box>
            <InputBox
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              defaultValue={email}
              validationFunction={verifyEmail}
              autoFocus
              type="email"
            />
            <NewPassword
              label="New password"
              name="password"
              fullWidth
              required
              validationFunction={verifyPassword}
            />
            <Box display={'flex'} gap={2} flexDirection={'column'} marginY={2}>
              <CheckBox
                name="gdprThirdPartyConsent"
                label={
                  <small>
                    You hereby expressly consent to us collecting and processing
                    your personal data and disclosing this information to
                    relevant third parties for the purpose of seeking employment
                    on your behalf?
                  </small>
                }
                required
              />

              <CheckBox
                name="termsAndConditions"
                label={
                  <small>
                    I have reviewd{' '}
                    <Link
                      href="#"
                      onClick={handleTermsAndConditionsClick}
                      color={'inherit'}
                    >
                      the privacy policy
                    </Link>
                  </small>
                }
                required
              />
            </Box>

            <FormErrorMessage />
          </Box>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            className="submit"
            sx={{
              width: '200px',
              margin: 'auto',
            }}
          >
            Register
          </Button>
          <Box width="100%" display="flex" justifyContent="center">
            <Typography variant="body2">
              Already have an account?
              <Link component={RouterLink} to="/login" marginLeft={2}>
                Log in
              </Link>
            </Typography>
          </Box>
        </Box>
      </Form>

      <Dialog open={displayModal} scroll="paper" fullWidth maxWidth={false}>
        <DialogContent>
          <TermsAndConditions />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDisplayModal(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </UserView>
  );
};

export default RegistrationView;
