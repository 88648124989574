/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CandidateService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Gets declarations in json format
     * @returns any OK
     * @throws ApiError
     */
    public getDeclarationJson(): CancelablePromise<{
        data: string;
        check: string;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                404: `Not found`,
            },
        });
    }

    /**
     * Gets a declaration form
     * @returns binary Created
     * @throws ApiError
     */
    public getDeclarationPdf(): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/pdf',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                404: `Not found`,
            },
        });
    }

}
