const TermsAndConditions = () => (
  <div>
    <h2>Coben Healthcare Ltd Privacy Policy.</h2>
    <p>
      Coben Healthcare Ltd is company registered in England &amp; Wales with
      registration number 10103621. Coben Healthcare Ltd is committed to
      ensuring that your privacy is protected. This privacy policy sets out how
      our business uses and protects any information that you give us, including
      any data you may provide through our websites or otherwise, when you
      register with us, correspond or apply for a job with us, require a role to
      be filled, purchase a product or service or take part in a competition
      (“Transaction”). If you provide us with personal information you can be
      assured that it will only be used in accordance with this privacy
      statement. This website is governed by the laws of England and Wales and
      the English and Welsh Courts shall have exclusive jurisdiction over it.
      For a copy of our latest terms and conditions of business, please contact
      us at GDPR@cobenmedical.com, alternatively you can view these via our
      website.
    </p>
    <h2>Information we collect from you</h2>
    <p>We may collect and process the following data about you:</p>
    <p>
      • Information that you provide by filling in forms on this site. This
      includes information provided at the time of registering to use our site,
      subscribing to our service, posting material or requesting further
      services. We may also ask you for information when you enter a competition
      or promotion or when you report a problem with our site.
    </p>
    <p>• If you contact us, we will keep a record of that correspondence.</p>
    <p>
      • We may also ask you to complete surveys that we use for research
      purposes, although you do not have to respond to them.
    </p>
    <p>
      • Details relating to our recruitment services and the supply of your
      services to our clients.
    </p>
    <p>
      Details of your visits to our site including, but not limited to, traffic
      data, location data, weblogs and other communication data, whether this is
      required for our own billing purposes or otherwise and the resources that
      you access.
    </p>
    <p>
      We collect information about you when you interact, correspond or enter
      into a Transaction with us whether by telephone, by electronic mail or by
      submitting the information through our websites or service applications.
      We will only ask for the information required for the Transaction and
      handle it in accordance with the United Kingdom’s Data Protection
      legislation. Uses made of the Information We use information held about
      you in the following ways:
    </p>
    <p>
      • To ensure that content from our site is presented in the most effective
      manner for you and for your computer.
    </p>
    <p>
      • To provide you with information, products or services that you request
      from us or which we feel may interest you, where you have consented to be
      contacted for such purposes.
    </p>
    <p>
      • To carry out our obligations arising from any contracts entered into
      between you and us.
    </p>
    <p>
      • To allow you to participate in interactive features of our service, when
      you choose to do so.
    </p>
    <p>• To notify you about changes to our service.</p>
    <p>
      Where you are applying for a job, we will make your information available
      to potential employers for the purposes of finding suitable employment for
      you.
    </p>
    <p>
      Most commonly, we will use your personal data in the following
      circumstances:
    </p>
    <p>
      • Where we need to perform the contract, we are about to enter or have
      entered with you.
    </p>
    <p>• Where we need to comply with a legal or regulatory obligation.</p>
    <p>
      We may also use your data, or permit selected third parties to use your
      data, to provide you with information about goods and services which may
      be of interest to you and we or they may contact you about these by email,
      post or telephone. If you do not want us to use your data in this way, or
      to pass your details on to third parties for marketing purposes, please
      tick the relevant box situated on the form on which we collect your data
      or contact us to let us know at the time of registering with us.
    </p>
    <h2>Your Rights</h2>
    <p>
      You have the right to ask us not to process your personal data for
      marketing purposes. We will inform you (before collecting your data) if we
      intend to use your data for such purposes or if we intend to disclose your
      information to any third party for such purposes. You can exercise your
      right to prevent such processing by click on the unsubscribe link at the
      bottom of the marketing email you were sent or email GDPR@cobenmedical.com
      Our site may, from time to time, contain links to and from the websites of
      our partner networks, advertisers and affiliates.
    </p>
    <p>
      If you follow a link to any of these websites, please note that these
      websites have their own privacy policies and that we do not accept any
      responsibility or liability for these policies. Please check these
      policies before you submit any personal data to these websites. Where you
      opt out of receiving these marketing messages, this will not apply to
      personal data provided to us because of other Transactions.
    </p>
    <p>
      Coben Healthcare ltd want to provide you with the best possible service
      and in order to do this we must make sure that your personal information
      is accurate and up to date; please keep us informed if your personal data
      changes during your registration with us. You may ask us to correct any
      inaccurate data about yourself or ask us to stop processing your data. If
      we are under a duty to disclose or share your personal data in order to
      comply with any legal obligation, or in order to enforce or apply our
      terms of use and other agreements; or to protect the rights, property, or
      safety of us, our customers, or others.
    </p>
    <p>
      This includes exchanging information with other companies and
      organisations for the purposes of fraud protection and credit risk
      reduction. You have the right to: withdraw your consent for us to process
      your information for any reason, request a copy of the information we hold
      about you or ask for your personal data to be deleted.
    </p>
    <p>
      If you would like to exercise any of these rights, please email
      GDPR@cobenmedical.com Please be aware if you wish to withdraw consent for
      Coben Healthcare ltd to process your information, then we may need to
      cease communication with you and you will be required to start the entire
      registration process again of all personal information should you wish to
      enter into a future transaction with Coben Healthcare ltd
    </p>
    <h2>Where we store your personal data</h2>
    <p>
      All information you provide to us is stored on our secure servers inside
      the European Economic Area (“EEA”). Where we have given you (or where you
      have chosen) a password which enables you to access certain parts of our
      site, you are responsible for keeping this password confidential. We ask
      you not to share a password with anyone. Unfortunately, the transmission
      of information via the internet is not completely secure.
    </p>
    <p>
      Although we will do our best to protect your personal data, we cannot
      guarantee the security of your data transmitted to our site; any
      transmission is at your own risk. Once we have received your information,
      we will use strict procedures and security features to try to prevent
      unauthorised access.
    </p>
    <p>
      We share your personal data within the Coben Healthcare Ltd. This will not
      involve transferring your data outside the European Economic Area (EEA).
      If the need arises you will be notified in writing and again prior
      consent. When transferring your personal data out of the EEA, we ensure a
      similar degree of protection is afforded to it by ensuring at least one of
      the following safeguards is implemented:
    </p>
    <p>
      • We will only transfer your personal data to countries that have been
      deemed to provide an adequate level of protection for personal data by the
      European Commission.
    </p>
    <p>
      • Where we use certain service providers, we may use specific contracts
      approved by the European Commission which give personal data the same
      protection it has in Europe.
    </p>
    <p>
      We have put in place appropriate security measures to prevent your
      personal data from being accidentally lost, used or accessed in an
      unauthorised way, altered or disclosed. In addition, we limit access to
      your personal data to those employees, agents, contractors and other third
      parties who have a business need to know. They will only process your
      personal data on our instructions and they are subject to a duty of
      confidentiality. We have put in place procedures to deal with any
      suspected personal data breach and will notify you and any applicable
      regulator of a breach where we are legally required to do so.
    </p>
    <h2>Cookies</h2>
    <p>
      We may collect information about your general internet usage by using a
      cookie file which is stored on the hard drive of your computer. Cookies
      are text files placed on your computer to collect standard internet log
      information and visitor behaviour information. This information is used to
      track visitor use of the website and to compile statistical reports on
      website activity. None of our cookies store personal information. They
      help us to improve our site and to deliver a better and more personalised
      service.
    </p>
    <h2>They enable us:</h2>
    <p>• To estimate our audience size and usage pattern.</p>
    <p>
      • To store information about your preferences, and so allow us to
      customise our site according to your individual interests.
    </p>
    <p>• To speed up your searches.</p>
    <p>• To recognise you when you return to our site.</p>
    <p>
      You may refuse to accept cookies by activating the setting on your browser
      which allows you to refuse the setting of cookies. However, if you select
      this setting you may be unable to access certain parts of our site. Unless
      you have adjusted your browser setting so that it will refuse cookies, our
      system will issue cookies when you log on to our site. Changes to our
      privacy Policy
      <br />
      Any changes we may make to our privacy policy in the future will be posted
      on this page and, where appropriate, notified to you by e-mail.
    </p>
  </div>
);

export { TermsAndConditions };
