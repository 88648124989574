import { Box, Container, Link } from '@mui/material';
import React from 'react';
import Copyrights from './Copyrights';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer: React.FC = () => {
  return (
    <Box component="footer" sx={{ backgroundColor: 'primary.main' }}>
      <Container
        sx={(theme) => ({
          display: 'flex',
          color: theme.palette.primary.contrastText,
          [theme.breakpoints.down('sm')]: {
            paddingX: 0,
            paddingTop: 1,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column-reverse',
          },
        })}
      >
        <Box my={2} flexGrow={1} fontSize="small">
          <Copyrights />
        </Box>
        <Box display="flex" alignItems="center" gap={1}>
          <Link
            href="https://twitter.com/CobenHealthcare"
            color="primary.contrastText"
            lineHeight={1}
            target="_blank"
          >
            <TwitterIcon />
          </Link>
          <Link
            href="https://www.facebook.com/Coben-Healthcare-110275274575771"
            color="primary.contrastText"
            lineHeight={1}
            target="_blank"
          >
            <FacebookIcon />
          </Link>
          <Link
            href="https://www.linkedin.com/company/cobenhealthcare/"
            color="primary.contrastText"
            lineHeight={1}
            target="_blank"
          >
            <LinkedInIcon />
          </Link>
          <Link
            href="https://www.instagram.com/cobenhealthcarerecruitment/"
            color="primary.contrastText"
            lineHeight={1}
            target="_blank"
          >
            <InstagramIcon />
          </Link>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
