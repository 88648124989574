import { VerificationDTO } from 'generated/profile';
import useApiClients from 'hooks/useApiClients';
import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react';

export type VerificationContextType = {
  verification: VerificationDTO | null;
  loading: boolean;
  fetchVerification: () => void;
};

const VerificationContext = createContext<VerificationContextType>({
  verification: null,
  loading: false,
  fetchVerification: () => {},
});

interface VerificationProviderProps {
  children: ReactNode;
}

const VerificationProvider = ({ children }: VerificationProviderProps) => {
  const [verification, setVerification] = useState<VerificationDTO | null>(
    null,
  );
  const [loading, setLoading] = useState(false);
  const { profileClient } = useApiClients();

  const fetchVerification = useCallback(() => {
    if (!profileClient) {
      return;
    }

    setLoading(true);

    profileClient.candidate
      .getSelfProfileVerification()
      .then((verification) => setVerification(verification))
      .catch((error) => {
        if (error.status === 404) {
          setVerification(null);
        } else {
          console.error('Error fetching verification:', error);
        }
      })
      .finally(() => setLoading(false));
  }, [profileClient]);

  useEffect(() => {
    if (profileClient) {
      fetchVerification();
    }
  }, [profileClient]);

  return (
    <VerificationContext.Provider
      value={{
        verification,
        loading,
        fetchVerification,
      }}
    >
      {children}
    </VerificationContext.Provider>
  );
};

export { VerificationContext, VerificationProvider };
