type JobTitle = {
  label: string;
  value: string;
};

type Pin = {
  type: string;
  required?: boolean;
};

export type Profession = {
  label: string;
  value: string;
  jobTitles: JobTitle[];
  pin?: Pin;
  smartcard?: boolean;
};

export type Professions = {
  [key: string]: Profession;
};

export const professions = {
  gp: {
    label: 'General Practitioner',
    value: 'gp',
    jobTitles: [
      {
        label: 'GP',
        value: 'gp',
      },
      {
        label: 'GP Registrar',
        value: 'gpRegistrar',
      },
    ],
    pin: {
      type: 'GMC',
      required: true,
    },
    smartcard: true,
  },
  nurse: {
    label: 'Nurse',
    value: 'nurse',
    jobTitles: [
      {
        label: 'Practice nurse',
        value: 'practiceNurse',
      },
      {
        label: 'Nurse Practitioner',
        value: 'nursePractitioner',
      },
      {
        label: 'Nurse Practitioner Prescriber',
        value: 'nursePractitionerPrescriber',
      },
      {
        label: 'Advanced Nurse Practitioner',
        value: 'advancedNursePractitioner',
      },
      {
        label: 'Emergency Nurse Practitioner',
        value: 'emergencyNursePractitioner',
      },
      {
        label: 'RGN',
        value: 'rgn',
      },
    ],
    pin: {
      type: 'NMC',
      required: true,
    },
    smartcard: true,
  },
  pharmacist: {
    label: 'Pharmacist',
    value: 'pharmacist',
    jobTitles: [
      {
        label: 'Advanced Clinical Practitioner',
        value: 'advancedClinicalPractitioner',
      },
      {
        label: 'Emergency Care Practitioner',
        value: 'emergencyCarePractitioner',
      },
      {
        label: 'Pharmacist Prescriber',
        value: 'pharmacistPrescriber',
      },
      {
        label: 'Pharmacist',
        value: 'pharmacist',
      },
    ],
    pin: 'GPhC',
    smartcard: true,
  },
  paramedic: {
    label: 'Paramedic',
    value: 'paramedic',
    jobTitles: [
      {
        label: 'Advanced Clinical Practitioner',
        value: 'advancedClinicalPractitioner',
      },
      {
        label: 'Paramedic',
        value: 'paramedic',
      },
      {
        label: 'Emergency Care Practitioner',
        value: 'emergencyCarePractitioner',
      },
    ],
    pin: {
      type: 'HCPC',
      required: true,
    },
    smartcard: true,
  },
  physiotherapist: {
    label: 'Physiotherapist',
    value: 'physiotherapist',
    jobTitles: [
      {
        label: 'Physiotherapist',
        value: 'physiotherapist',
      },
      {
        label: 'Advanced Clinical Practitioner',
        value: 'advancedClinicalPractitioner',
      },
      {
        label: 'First Contact Physiotherapist',
        value: 'firstContactPhysiotherapist',
      },
    ],
    smartcard: true,
  },
  other: {
    label: 'Other',
    value: 'other',
    jobTitles: [
      {
        label: 'Phlebotomist',
        value: 'phlebotomist',
      },
      {
        label: 'Healthcare Assistant',
        value: 'healthcareAssistant',
      },
      {
        label: 'Nursing Associate',
        value: 'nursingAssociate',
      },
    ],
  },
};
