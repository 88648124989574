import { useAuth } from 'hooks/useAuth';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router';
import VerificationView from 'views/VerificationView';

const VerificationContainer: FC = () => {
  const { unverifiedEmail, verifyEmail, sendVerificationEmail } = useAuth();
  const [verificationError, setVerificationError] = useState<any | null>(null);
  const navigate = useNavigate();
  const handleSubmit = (code: string) => {
    return verifyEmail(code)
      .then(() => {
        navigate('/login');
      })
      .catch((err) => {
        console.error(err);
        setVerificationError({ code: 'Invalid code' });
      });
  };

  const handleResendCodeClick = () => {
    sendVerificationEmail(unverifiedEmail || '').then(() => {
      console.log('Verification email sent');
    });
  };

  return (
    <VerificationView
      onSubmit={handleSubmit}
      onResendCodeClick={handleResendCodeClick}
      email={unverifiedEmail!}
      verificationError={verificationError}
    />
  );
};

export default VerificationContainer;
