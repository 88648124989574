import { professions } from 'components/constants/professions';
import ProfessionalDetailsControl from 'components/profile/ProfessionalDetailsControl';
import { ProfileDTO } from 'generated/profile';
import useProfile from 'hooks/useProfile';
import ProfileContainer from './ProfileContainer';
import { mapProfileToFormData } from 'helpers/profile';

const ProfessionalContainer = () => {
  const { profile, saveProfile } = useProfile();

  const mapFormDataToDTO = (formData: any) => {
    const updatedProfile: ProfileDTO = {
      ...profile!,
      professionalDetails: {
        ...profile?.professionalDetails,
        profession: formData.profession[0],
        jobTitle: formData.jobTitle[0],
        professionalPin: formData.professionalPin[0],
        professionalRestrictions:
          formData.professionalRestrictions[0] === 'yes'
            ? true
            : formData.professionalRestrictions[0] === 'no'
            ? false
            : undefined,
      },
    };

    if (formData.appraisalAndRevalidation[0] === 'yes') {
      updatedProfile.professionalDetails!.appraisalArrandged = true;
      updatedProfile.professionalDetails!.appraisalDetails = {
        appraisedProfessionalBody: formData.appraisedProfessionalBody[0],
        appraiserName: formData.appraiserName[0],
        dateOfLastAppraisal: formData.dateOfLastAppraisal[0],
        emailAddress: formData.emailAddress[0],
        registrationNumber: formData.registrationNumber[0],
      };
    } else if (formData.appraisalAndRevalidation[0] === 'no') {
      updatedProfile.professionalDetails!.appraisalArrandged = false;
    }

    return updatedProfile;
  };

  const handleOnSubmit = (profile: any) => {
    return saveProfile(mapFormDataToDTO(profile));
  };

  return (
    <ProfileContainer
      step="PROFESSIONAL"
      controls={[
        <ProfessionalDetailsControl
          key="professionalDetails"
          onSubmit={handleOnSubmit}
          professions={professions}
          profile={mapProfileToFormData(profile!)}
        />,
      ]}
    />
  );
};

export default ProfessionalContainer;
