import {
  FormControlLabel,
  Checkbox,
  Typography,
  CheckboxProps,
  FormHelperText,
  FormControl,
  useTheme,
} from '@mui/material';
import { useState, useRef, useCallback, useEffect, useContext } from 'react';
import { ErrorCodes } from 'components/form';
import React from 'react';
import { FormContext } from 'components/form/formContext';

interface CheckBoxProps extends CheckboxProps {
  label?: React.ReactNode;
  errorMessages?: Partial<Record<ErrorCodes, string>>;
}

const CheckBox = ({
  label,
  errorMessages,
  onChange,
  ...rest
}: CheckBoxProps) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const inputRef = useRef<HTMLInputElement>(null);
  const { isSubmissionAttempted } = useContext(FormContext);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    validateInput();
    onChange && onChange(event, event.target.checked);
  };

  const getErrorCode = () => {
    let errorKey: ErrorCodes | undefined = undefined;
    if (inputRef.current) {
      // can't use Object.keys here

      for (const key in inputRef.current.validity) {
        if (inputRef.current.validity[key as keyof ValidityState]) {
          errorKey = key as ErrorCodes;
          break;
        }
      }

      if (inputRef.current.validity.customError) {
        errorKey = inputRef.current.validationMessage as ErrorCodes;
      }

      return errorKey === 'valid' ? undefined : errorKey;
    }
  };

  const updateErrorMessage = useCallback(() => {
    const errorCode = getErrorCode();
    const isInvalid = isSubmissionAttempted && errorCode ? true : false;
    const errorText = isInvalid
      ? errorMessages?.[errorCode!] || inputRef.current?.validationMessage
      : '';

    setErrorMessage(errorText);
  }, [errorMessages, isSubmissionAttempted]);

  const validateInput = useCallback(async () => {
    if (inputRef.current) {
      const target = inputRef.current;

      // resetting the custom validity state
      target.setCustomValidity('');
      updateErrorMessage();
    }
  }, [updateErrorMessage]);

  useEffect(() => {
    // running the validation on mount to set the initial validity state
    validateInput();
  }, [validateInput]);

  const showError = isSubmissionAttempted && !!errorMessage;
  const theme = useTheme();

  return (
    <FormControl>
      <FormControlLabel
        sx={{
          alignItems: 'flex-start',
          lineHeight: 1,
        }}
        control={
          <Checkbox
            {...rest}
            sx={{
              color: showError ? 'error.main' : theme.palette.text.primary,
            }}
            onChange={handleOnChange}
            inputRef={inputRef}
          />
        }
        label={
          <Typography variant="body1" marginTop={1}>
            {label}
          </Typography>
        }
      />
      <FormHelperText error>{errorMessage}</FormHelperText>
    </FormControl>
  );
};

export { CheckBox };
