import {
  DialogTitle,
  Typography,
  DialogContent,
  List,
  ListItem,
  Box,
} from '@mui/material';
import FilePresentOutlined from '@mui/icons-material/FilePresentOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { FC } from 'react';

interface UploadHelpContentProps {
  title: string;
  description: string;
  rules?: string[];
  closingStatement?: string;
}

const UploadHelpContent: FC<UploadHelpContentProps> = ({
  title,
  description,
  rules,
  closingStatement,
}) => (
  <Box
    sx={(theme) => ({
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    })}
  >
    <DialogTitle
      alignItems={'center'}
      display={'flex'}
      gap={2}
      borderBottom={'1px solid #ffffffcc'}
    >
      <FilePresentOutlined sx={{ fontSize: '2rem' }} />
      <Typography variant={'h4'} fontWeight={100}>
        {title}
      </Typography>
    </DialogTitle>
    <DialogContent sx={{ marginTop: 2 }}>
      <Typography variant="body1" marginBottom={2} fontWeight={100}>
        {description}
      </Typography>
      {rules && (
        <List>
          {rules.map((item, index) => (
            <ListItem key={index} alignItems="flex-start" sx={{ gap: 2 }}>
              <FiberManualRecordIcon
                sx={{ fontSize: '10px', marginTop: '0.5em' }}
              />
              <Typography fontWeight={100}>{item}</Typography>
            </ListItem>
          ))}
        </List>
      )}
      {closingStatement && (
        <Typography variant="body1" marginTop={2} fontWeight={100}>
          {closingStatement}
        </Typography>
      )}
    </DialogContent>
  </Box>
);

export default UploadHelpContent;
