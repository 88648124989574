import { Button, Link, Typography } from '@mui/material';
import { ProfileDTO } from 'generated/profile';
import { FC } from 'react';

interface HomeViewActionRequiredContentProps {
  profile: ProfileDTO;
}

const HomeViewActionRequiredContent: FC<HomeViewActionRequiredContentProps> = ({
  profile,
}) => (
  <>
    <Typography variant="h5" mb={2} fontWeight="600">
      {profile.personalDetails?.forename
        ? `Hi ${profile.personalDetails?.forename},`
        : 'Hello,'}
    </Typography>
    <div>
      <p>Thank you for registering with us.</p>
      <p>
        Your profile is currently under review by our compliance team. We have
        identified some areas that require your attention and editing to
        complete the verification process.
      </p>
      <p>
        Please review the following sections and update the necessary
        information accordingly:
      </p>
      <ul>
        <li>Personal Details</li>
        <li>Professional details</li>
        <li>References</li>
        <li>Documents</li>
      </ul>
      <p>
        Once you&apos;ve made the required edits, you can resubmit your profile
        for verification.
      </p>
      <p>
        If you have any questions or need assistance with the editing process,
        our team is here to help. Feel free to contact us at:
      </p>
      <p>
        Phone:{' '}
        <Link fontWeight={'bold'} href="tel://+441254693067">
          01254 693067
        </Link>
      </p>
      <p>
        Email:{' '}
        <Link fontWeight={'bold'} href="mailto:compliance@cobenhealthcare.com">
          compliance@cobenhealthcare.com
        </Link>
      </p>
    </div>
  </>
);

export default HomeViewActionRequiredContent;
