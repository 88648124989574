import useProfile from 'hooks/useProfile';
import { FC } from 'react';
import ProfileContainer from './ProfileContainer';
import ReferencesControl from 'components/profile/ReferencesControl';
import { ProfileDTO } from 'generated/profile';

const ReferencesContainer: FC = () => {
  const { profile, saveProfile } = useProfile();

  const mapFormDataToDTO = (formData: any) => {
    const updatedProfile: ProfileDTO = {
      ...profile!,
      references: [],
    };

    formData.reference_name?.forEach((name: string, index: number) => {
      updatedProfile.references!.push({
        id: formData.reference_id[index] || undefined,
        name,
        organisation: formData.reference_organisation[index],
        position: formData.reference_position[index],
        grade: formData.reference_grade[index],
        email: formData.reference_email[index],
        phoneNumber: formData.reference_phone[index],
        workEmail: formData.reference_work_email[index],
        periodWorked: {
          from: formData.reference_worked_from[index],
          to: formData.reference_worked_to[index],
        },
        gradeWorkedAt: formData.reference_grade_worked_at[index],
      });
    });

    return updatedProfile;
  };

  const handleOnSubmit = (formData: any) => {
    return saveProfile(mapFormDataToDTO(formData));
  };

  return (
    <ProfileContainer
      step="REFERENCES"
      controls={[
        <ReferencesControl
          profile={profile!}
          key="references"
          onSubmit={handleOnSubmit}
        />,
      ]}
    />
  );
};

ReferencesContainer.displayName = 'ReferencesContainer';

export default ReferencesContainer;
