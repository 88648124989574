export const UPPERCASE_REGEX = new RegExp(/.*[A-Z]/);
export const LOWERCASE_REGEX = new RegExp(/.*[a-z]/);
export const NUMBER_REGEX = new RegExp(/.*\d/);
export const LENGTH_REGEX = new RegExp(/.{8,}$/);
export const SPECIAL_CHARS_REGEX = new RegExp(
  /.*[\-’\/`~!#*$@_%+=.,^&\(\)\{\}\[\]\|;:”<>?\\]/,
);

export const combineRegExp = (expressions: RegExp[]) => {
  return new RegExp(
    `^(?=${expressions.map((exp) => exp.source).join(')(?=')}).*$`,
  );
};
