import React, { useCallback } from 'react';
import { forwardRef } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FormContext } from './formContext';

interface Props
  extends Omit<React.HTMLProps<HTMLFormElement>, 'onSubmit' | 'onChange'> {
  children: React.ReactNode;
  className?: string;
  onSubmit: (data: FormData) => Promise<any>;
  onChange?: (e: React.FormEvent<HTMLFormElement>) => void;
}

const Form = forwardRef<HTMLFormElement, Props>(
  ({ children, onSubmit, onChange, ...rest }: Props, ref) => {
    const [isDirty, setIsDirty] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [submitted, setSubmitted] = React.useState(false);

    const [key, setKey] = React.useState(0);

    const [isSubmissionAttempted, setIsSubmissionAttempted] =
      React.useState(false);
    const [submissionError, setSubmissionError] = React.useState<Error | null>(
      null,
    );

    const reset = useCallback(() => {
      setIsDirty(false);
      setIsSubmissionAttempted(false);
      setSubmissionError(null);
      setSubmitted(false);
      setKey((state) => state + 1);
    }, []);

    const handleChange = useCallback(
      (e: React.FormEvent<HTMLFormElement>) => {
        e.stopPropagation();
        setIsDirty(true);
        setSubmissionError(null);
        setSubmitted(false);
        onChange?.(e);
      },
      [onChange],
    );

    const handleSubmit = useCallback(
      (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsSubmissionAttempted(true);

        const formElement = e.target as HTMLFormElement;

        const isValid = formElement.checkValidity();

        // moving focus to the first invalid field
        const firstInvalidField = formElement.querySelector(
          ':invalid:not(fieldset)',
        ) as HTMLInputElement;

        if (firstInvalidField) {
          const firstFocusableElement = (firstInvalidField.closest(
            'input:not([hidden]):not([aria-hidden]), button,textarea',
          ) ||
            firstInvalidField.parentNode?.querySelector('div') ||
            document.querySelector(
              `[data-focusfor = '${firstInvalidField.id}']`,
            )) as HTMLInputElement;

          firstFocusableElement?.focus();
        }

        // submit the dataObject if isValid===true
        if (isValid) {
          setSubmissionError(null);
          setIsSubmitting(true);
          const dataObject = new FormData(formElement);

          onSubmit(dataObject)
            .then(() => {
              // if successful, set isDirty to false
              setIsDirty(false);
              setSubmitted(true);
            })
            .catch((e) => {
              // if unsuccessful  setSubmissionError
              console.log('error from the form', e);

              setSubmissionError(e);
            })
            .finally(() => {
              setIsSubmitting(false);
            });
        }
      },
      [onSubmit],
    );

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <FormContext.Provider
          value={{
            isDirty,
            setIsDirty,
            submissionError,
            setSubmissionError,
            isSubmissionAttempted,
            setIsSubmissionAttempted,
            isSubmitting,
            setIsSubmitting,
            submitted,
            setSubmitted,
            reset,
          }}
        >
          <form
            ref={ref}
            {...rest}
            onChange={handleChange}
            onSubmit={handleSubmit}
            noValidate
            key={key}
          >
            {children}
          </form>
        </FormContext.Provider>
      </LocalizationProvider>
    );
  },
);

Form.displayName = 'Form';

export { Form };
