import { Amplify, Auth } from 'aws-amplify';
import { AuthContextType } from 'components/types/auth';
import { User } from 'components/types/user';
import useConfig from 'hooks/useConfig';
import React, { createContext, useEffect, useState } from 'react';

const AuthContext = createContext<AuthContextType>({
  user: undefined,
  unverifiedEmail: null,
  loading: true,
  login: () => Promise.resolve(false),
  logout: () => Promise.resolve(),
  signup: () => Promise.resolve(false),
  verifyEmail: () => Promise.resolve(false),
  sendVerificationEmail: () => Promise.resolve(false),
  resetPassword: () => Promise.resolve(false),
  sendPasswordResetCode: () => Promise.resolve(false),
});

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null | undefined>(undefined);
  const [unverifiedEmail, setUnverifiedEmail] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const config = useConfig();

  useEffect(() => {
    if (config) {
      Amplify.configure(config.amplify);
      checkUser(); // Check if a user is already authenticated on component mount
    }
  }, [config]);

  const checkUser = () => {
    Auth.currentAuthenticatedUser()
      .then((userData) => {
        setUser(userData);
      })
      .catch(() => {
        setUser(null);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const resetPassword = (
    username: string,
    newPassword: string,
    code: string,
  ) => {
    setLoading(true);
    return Auth.forgotPasswordSubmit(username, code, newPassword)
      .then(() => {
        return true;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const login = (username: string, password: string) => {
    setLoading(true);
    return Auth.signIn(username, password)
      .then((userData) => {
        setUser(userData);
        return true;
      })
      .catch((error) => {
        if (error.code === 'UserNotConfirmedException') {
          setUnverifiedEmail(username);
        }
        throw error;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const logout = () => {
    return Auth.signOut()
      .then(() => {
        setUser(null);
      })
      .catch((error) => {
        console.error('Error signing out:', error);
      });
  };

  const signup = (username: string, password: string) => {
    setLoading(true);
    return Auth.signUp({
      username,
      password,
      attributes: {
        email: username,
      },
    })
      .then(() => {
        setUnverifiedEmail(username);
        return true;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const verifyEmail = (code: string) => {
    setLoading(true);
    return Auth.confirmSignUp(unverifiedEmail!, code)
      .then(() => {
        setUnverifiedEmail(null);
        return true;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const sendVerificationEmail = (email: string) => {
    setLoading(true);
    return Auth.resendSignUp(email)
      .then(() => {
        return true;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const sendPasswordResetCode = (email: string) => {
    setLoading(true);
    return Auth.forgotPassword(email)
      .then(() => {
        return true;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const authContextValue: AuthContextType = {
    user,
    unverifiedEmail,
    loading,
    login,
    logout,
    signup,
    verifyEmail,
    sendVerificationEmail,
    resetPassword,
    sendPasswordResetCode,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
