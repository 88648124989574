import { InputBox, InputBoxProps } from 'components/inputBox';
import dayjs from 'dayjs';

interface DateBoxProps extends Omit<InputBoxProps, 'value' | 'defaultValue'> {
  value?: string | Date | number;
  defaultValue?: string | Date | number;
}

const DateBox = ({ value, defaultValue, ...props }: DateBoxProps) => {
  const formatedValue = value && dayjs(new Date(value)).format('YYYY-MM-DD');

  const formatedDefaultValue =
    defaultValue && dayjs(new Date(defaultValue)).format('YYYY-MM-DD');

  return (
    <InputBox
      type="date"
      InputLabelProps={{
        shrink: true,
      }}
      value={formatedValue}
      defaultValue={formatedDefaultValue}
      {...props}
    />
  );
};

export { DateBox };
