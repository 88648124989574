import { ProfileDTO } from 'generated/profile';

const mapProfileToFormData = (profile: ProfileDTO | null) => {
  const formData: any = {
    profession: profile?.professionalDetails?.profession,
    jobTitle: profile?.professionalDetails?.jobTitle,
    professionalPin: profile?.professionalDetails?.professionalPin,
    professionalRestrictions: profile?.professionalDetails
      ?.professionalRestrictions
      ? 'yes'
      : profile?.professionalDetails?.professionalRestrictions === false
      ? 'no'
      : undefined,
    appraisalAndRevalidation: profile?.professionalDetails?.appraisalArrandged
      ? 'yes'
      : profile?.professionalDetails?.appraisalArrandged === false
      ? 'no'
      : undefined,
    appraisedProfessionalBody:
      profile?.professionalDetails?.appraisalDetails?.appraisedProfessionalBody,
    appraiserName:
      profile?.professionalDetails?.appraisalDetails?.appraiserName,
    dateOfLastAppraisal:
      profile?.professionalDetails?.appraisalDetails?.dateOfLastAppraisal,
    emailAddress: profile?.professionalDetails?.appraisalDetails?.emailAddress,
    registrationNumber:
      profile?.professionalDetails?.appraisalDetails?.registrationNumber,
    reference_id: [],
    reference_name: [],
    reference_organisation: [],
    reference_position: [],
    reference_grade: [],
    reference_email: [],
    reference_phone: [],
    reference_work_email: [],
    reference_worked_from: [],
    reference_worked_to: [],
    reference_grade_worked_at: [],
  };

  profile?.references?.forEach((reference, index) => {
    formData.reference_id[index] = reference.id;
    formData.reference_name[index] = reference.name;
    formData.reference_organisation[index] = reference.organisation;
    formData.reference_position[index] = reference.position;
    formData.reference_grade[index] = reference.grade;
    formData.reference_email[index] = reference.email;
    formData.reference_phone[index] = reference.phoneNumber;
    formData.reference_work_email[index] = reference.workEmail;
    formData.reference_worked_from[index] = reference.periodWorked?.from;
    formData.reference_worked_to[index] = reference.periodWorked?.to;
    formData.reference_grade_worked_at[index] = reference.gradeWorkedAt;
  });
  return formData;
};

export { mapProfileToFormData };
