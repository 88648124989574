import { Box } from '@mui/material';
import { FC } from 'react';

interface LayoutProps {
  header?: React.ReactNode;
  children: React.ReactNode;
  aside?: React.ReactNode;
  footer?: React.ReactNode;
  showBackgroundImage?: boolean;
}

const Layout: FC<LayoutProps> = ({
  header,
  footer,
  children,
  aside,
  showBackgroundImage = true,
}) => {
  return (
    <Box
      sx={(theme) => ({
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.grey[100],
      })}
    >
      {header && (
        <Box
          component="header"
          sx={(theme) => ({
            borderBottom: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.common.white,
          })}
        >
          {header}
        </Box>
      )}
      <Box
        position={'relative'}
        sx={{
          flexGrow: 1,
          display: 'flex',

          backgroundImage: showBackgroundImage
            ? {
                md: `url('/bg-unsplash.jpeg')`,
              }
            : 'none',
          backgroundSize: 'cover',
          backgroundPosition: 'center bottom',
        }}
      >
        <Box
          component="main"
          position={'absolute'}
          sx={{ inset: 0 }}
          overflow={'auto'}
        >
          {children}
        </Box>
        {aside && <Box component="aside">{aside}</Box>}
      </Box>
      <footer>{footer}</footer>
    </Box>
  );
};

export default Layout;
