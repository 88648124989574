import { Attachment, AttachmentProps } from 'components/attachment/Attachment';
import { AttachmentTypeType } from 'components/profile/DocumentsControl';
import { UploadedItem } from 'contexts/MediaContext';
import useMedia from 'hooks/useMedia';
import useProfile from 'hooks/useProfile';
import { FC, useEffect, useState } from 'react';

interface AttachmentContainerProps
  extends Omit<AttachmentProps, 'onFileSelected' | 'progress'> {
  type: AttachmentTypeType;
  onUploadComplete?: (uploadedItem: UploadedItem) => void;
  checkValidity?: (
    filename: string,
    type: AttachmentTypeType,
  ) => Promise<boolean>;
}

const AttachmentContainer: FC<AttachmentContainerProps> = ({
  type,
  onUploadComplete,
  checkValidity = () => Promise.resolve(true),
  filename,
  required,
  error,
  ...rest
}) => {
  const { uploadMedia } = useMedia();
  const { profile } = useProfile();
  const [progress, setProgress] = useState(filename ? 100 : 0);
  const [fileUploading, setFileUploading] = useState<File>();
  const [errorMessage, setErrorMessage] = useState<Error>();
  const [key, setKey] = useState(0);

  const onProgressUpdate = (progress: number) => {
    setProgress(progress);
  };

  const handleFileSelected = (file: File) => {
    setErrorMessage(undefined);
    checkValidity(file.name, type)
      .then(() => {
        setFileUploading(file);
        uploadMedia(profile?.id!, type, file, onProgressUpdate)
          .then((data) => {
            return onUploadComplete?.(data);
          })
          .catch((error) => {
            // re-render component to clear file input
            setKey((prev) => prev + 1);
            setErrorMessage(error);
          })
          .finally(() => {
            requestAnimationFrame(() => {
              requestAnimationFrame(() => {
                setFileUploading(undefined);
              });
            });
          });
      })
      .catch((err: Error) => {
        setErrorMessage(err);
      });
  };

  useEffect(() => {
    setProgress(filename ? 100 : 0);
  }, [filename]);

  return (
    <Attachment
      key={key}
      required={required}
      onFileSelected={handleFileSelected}
      progress={progress}
      filename={filename || fileUploading?.name}
      error={error || errorMessage}
      {...rest}
    />
  );
};

export default AttachmentContainer;
