/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CommentRequestDTO } from '../models/CommentRequestDTO';
import type { ProfileDTO } from '../models/ProfileDTO';
import type { ProfileSummaryDTO } from '../models/ProfileSummaryDTO';
import type { VerificationDTO } from '../models/VerificationDTO';
import type { VerificationEntityIdDTO } from '../models/VerificationEntityIdDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdminService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all profiles
     * @param lastKey Last key
     * @param limit Limit
     * @returns ProfileSummaryDTO Success
     * @throws ApiError
     */
    public getAllProfiles(
        lastKey?: string,
        limit?: number,
    ): CancelablePromise<Array<ProfileSummaryDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/profiles',
            query: {
                'lastKey': lastKey,
                'limit': limit,
            },
        });
    }

    /**
     * Create profile
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public createProfile(
        requestBody: ProfileDTO,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/profiles',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get profile by id
     * @param id Id of the profile
     * @returns ProfileDTO Success
     * @throws ApiError
     */
    public getProfileById(
        id: string,
    ): CancelablePromise<ProfileDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/profiles/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Update profile
     * @param id Id of the profile
     * @param requestBody
     * @returns ProfileDTO Success
     * @throws ApiError
     */
    public updateProfile(
        id: string,
        requestBody: ProfileDTO,
    ): CancelablePromise<ProfileDTO> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/profiles/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Delete profile
     * @param id Id of the profile
     * @returns any Success
     * @throws ApiError
     */
    public deleteProfile(
        id: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/profiles/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Get profile verification
     * @param id Id of the profile
     * @returns VerificationDTO Success
     * @throws ApiError
     */
    public getProfileVerification(
        id: string,
    ): CancelablePromise<VerificationDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/profiles/{id}/verification',
            path: {
                'id': id,
            },
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Post verification comment
     * @param id
     * @param requestBody
     * @returns VerificationDTO Success
     * @throws ApiError
     */
    public postVerificationComment(
        id: string,
        requestBody: CommentRequestDTO,
    ): CancelablePromise<VerificationDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/profiles/{id}/verification/comments',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Approve verification
     * @param id
     * @param requestBody
     * @returns VerificationDTO Success
     * @throws ApiError
     */
    public approveVerificationSection(
        id: string,
        requestBody: {
            entityId: VerificationEntityIdDTO;
            attributes?: Record<string, any>;
        },
    ): CancelablePromise<VerificationDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/profiles/{id}/verification/approve',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
            },
        });
    }

}
