import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import { Box, Collapse, IconButton, Typography } from '@mui/material';
import { FC, useState } from 'react';
interface VerificationStatusLogsProps {
  logs?: string[];
  itemsToDisplay?: number;
}

const VerificationStatusLogs: FC<VerificationStatusLogsProps> = ({
  logs = [],
  itemsToDisplay = 2,
}) => {
  const [viewAll, setViewAll] = useState(false);

  const toggleViewAll = () => {
    setViewAll(!viewAll);
  };

  const logsToDisplay = logs.slice(-itemsToDisplay);
  const logsToHide = logs.slice(0, logs.length - itemsToDisplay);

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      gap={1}
      alignItems={'flex-start'}
    >
      <Collapse in={viewAll}>
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          {logsToHide.map((log) => (
            <Box sx={{ color: (theme) => theme.palette.grey[500] }} key={log}>
              <Typography variant="body2" component="p" key={log}>
                {log}
              </Typography>
            </Box>
          ))}
        </Box>
      </Collapse>

      {logsToHide.length ? (
        <IconButton
          size="small"
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={toggleViewAll}
        >
          {viewAll ? <UnfoldLessIcon /> : <MoreHorizIcon />}
        </IconButton>
      ) : null}
      {logsToDisplay.map((log) => (
        <Box
          sx={{ color: (theme) => theme.palette.grey[500] }}
          key={log}
          display={'flex'}
          flexDirection={'column'}
          gap={1}
        >
          <Typography variant="body2" component="p" key={log}>
            {log}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default VerificationStatusLogs;
