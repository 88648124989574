import { Alert, Box, Collapse, MenuItem } from '@mui/material';

import { Professions } from 'components/constants/professions';
import { DateBox } from 'components/dateBox';
import { InputBox } from 'components/inputBox';
import { OptionGroup } from 'components/optionGroup';
import { SelectBox } from 'components/selectBox';

import { FC, useMemo, useState } from 'react';

interface ProfessionalDetailsProps {
  value?: { [key: string]: any };
  professions: Professions;
}

const ProfessionalDetails: FC<ProfessionalDetailsProps> = ({
  value,
  professions,
}) => {
  const [professionalRestrictions, setProfessionalRestrictions] = useState<
    boolean | undefined
  >(value?.professionalRestrictions === 'yes');

  const [appraisalAndRevalidation, setAppraisalAndRevalidation] = useState<
    boolean | undefined
  >(value?.appraisalAndRevalidation === 'yes');

  const [profession, setProfession] = useState<string | undefined>(
    value?.profession,
  );

  const jobTitles = useMemo(() => {
    if (!profession) {
      return [];
    }
    const professionData = professions[profession];
    if (!professionData) {
      return [];
    }
    return professionData.jobTitles || [];
  }, [profession]);

  const pin = useMemo(() => {
    if (!profession) {
      return undefined;
    }
    const professionData = professions[profession];
    if (!professionData) {
      return undefined;
    }
    return professionData.pin;
  }, [profession]);

  const smartCard = useMemo(() => {
    if (!profession) {
      return undefined;
    }
    const professionData = professions[profession];
    if (!professionData) {
      return undefined;
    }
    return professionData.smartcard;
  }, [profession]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <SelectBox
        labelId="professionLabel"
        label="Profession"
        name="profession"
        onChange={(e) => setProfession(e.target.value as string)}
        required
        defaultValue={value?.profession || ''}
      >
        {Object.entries(professions).map(([id, profession]) => (
          <MenuItem key={id} value={id}>
            {profession.label}
          </MenuItem>
        ))}
      </SelectBox>
      <Collapse in={jobTitles.length > 0}>
        <SelectBox
          label="Job title"
          name="jobTitle"
          fullWidth
          required
          defaultValue={value?.jobTitle || ''}
        >
          {jobTitles.map((jobTitle: any) => (
            <MenuItem key={jobTitle.value} value={jobTitle.value}>
              {jobTitle.label}
            </MenuItem>
          ))}
        </SelectBox>
      </Collapse>

      <Collapse in={!!pin}>
        <InputBox
          name="professionalPin"
          label="Professional PIN"
          defaultValue={value?.professionalPin || ''}
          required={pin?.required}
        />
      </Collapse>

      <Collapse in={!!smartCard}>
        <InputBox
          name="smartCardNumber"
          label="Smart card number"
          defaultValue={value?.smartCardNumber || ''}
        />
      </Collapse>

      <OptionGroup
        name="professionalRestrictions"
        onChange={(e) => setProfessionalRestrictions(e.target.value === 'yes')}
        options={[
          { value: 'yes', label: 'Yes' },
          { value: 'no', label: 'No' },
        ]}
        title="Restrictions"
        description="Do you have any restrictions against your professional registration?"
        row
        defaultValue={value?.professionalRestrictions || ''}
        required
      />

      <Collapse in={professionalRestrictions}>
        <Alert severity="info">
          Our compliance team will be in touch to discuss this with you
        </Alert>
      </Collapse>

      <OptionGroup
        name="appraisalAndRevalidation"
        onChange={(e) => setAppraisalAndRevalidation(e.target.value === 'yes')}
        options={[
          { value: 'yes', label: 'Yes' },
          { value: 'no', label: 'No' },
        ]}
        title="Appraisal"
        description="Have you made formal arrangements to be appraised regularly by an appropriately trained medical practitioner within an approved NHS appraisal System?"
        required
        defaultValue={value?.appraisalAndRevalidation || ''}
        row
      >
        <Collapse in={appraisalAndRevalidation} unmountOnExit mountOnEnter>
          <Box display="flex" flexDirection="column" gap={2} marginTop={2}>
            <InputBox
              name="appraiserName"
              label="Appraiser name"
              defaultValue={value?.appraiserName || ''}
              required
              fullWidth
            />
            <InputBox
              name="appraisedProfessionalBody"
              label="Appraiser professional body"
              defaultValue={value?.appraisedProfessionalBody || ''}
              required
              fullWidth
            />
            <InputBox
              name="registrationNumber"
              label="Registration number"
              defaultValue={value?.registrationNumber || ''}
              required
              fullWidth
            />
            <InputBox
              name="emailAddress"
              type="email"
              label="Email address"
              defaultValue={value?.emailAddress || ''}
              required
              fullWidth
            />

            <DateBox
              label="Date of last appraisal"
              name="dateOfLastAppraisal"
              defaultValue={value?.dateOfLastAppraisal || ''}
              required
            />

            <Alert severity="info">
              You will be asked to upload your latest appraisal in our document
              section
            </Alert>
          </Box>
        </Collapse>
      </OptionGroup>
    </Box>
  );
};

export { ProfessionalDetails };
