import { FC, useContext, useState } from 'react';
import { ReferenceCard } from './ReferenceCard';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from '@mui/material';
import { ReferenceForm } from './ReferenceForm';
import { FormContext } from './form/formContext';
import { SubmitButton } from './submitButton';
import { CancelButton } from './cancelButton';

interface Reference {
  reference_id?: string;
  reference_name?: string;
  reference_organisation?: string;
  reference_position?: string;
  reference_phone?: string;
  reference_grade?: string;
  reference_email?: string;
  reference_work_email?: string;
  reference_worked_from?: string;
  reference_worked_to?: string;
  reference_grade_worked_at?: string;
}

interface ReferenceContainerProps {
  values?: Reference;
  onAdd?: (data: any) => void;
  onUpdate?: (data: any) => void;
  onRemove?: () => void;
}

const ReferenceCardContainer: FC<ReferenceContainerProps> = ({
  values,
  onAdd,
  onUpdate,
  onRemove,
}) => {
  const [editing, setEditing] = useState(false);
  const [defaultValues, setDefaultValues] = useState<Reference | undefined>(
    values,
  );
  const { setIsDirty } = useContext(FormContext);

  const isNew = !defaultValues?.reference_name;

  const onSubmit = async (data: any) => {
    const formData = Object.fromEntries(data.entries());
    // This is a hack since we can't trigger the change event on the form
    setIsDirty(true);

    setDefaultValues(formData);
    setEditing(false);
    isNew ? onAdd?.(formData) : onUpdate?.(formData);
  };

  const onCancel = () => {
    setEditing(false);
  };

  const handleOnRemove = () => {
    // This is a hack since we can't trigger the change event on the form
    setIsDirty(true);
    setEditing(false);
    onRemove?.();
  };

  const theme = useTheme();

  return (
    <>
      <ReferenceCard
        defaultValues={defaultValues}
        onClick={() => setEditing(true)}
      />
      <Dialog
        open={editing}
        maxWidth={'xs'}
        fullWidth
        onClose={onCancel}
        scroll="paper"
      >
        <DialogTitle>Reference</DialogTitle>
        <DialogContent>
          <Box paddingTop={1}>
            <ReferenceForm
              onSubmit={onSubmit}
              defaultValues={defaultValues}
              id="reference_form"
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor: theme.palette.grey[100],
            boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.3)',
            display: 'flex',
            justifyContent: 'space-between',
            padding: 2,
          }}
        >
          {isNew ? (
            <div />
          ) : (
            <Button
              onClick={handleOnRemove}
              color={'error'}
              startIcon={<DeleteForeverIcon />}
            >
              Remove
            </Button>
          )}
          <Box display={'flex'} gap={2}>
            <CancelButton onClick={onCancel} form="reference_form">
              Cancel
            </CancelButton>
            <SubmitButton form="reference_form">
              {isNew ? 'Add' : 'Update'}
            </SubmitButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export { ReferenceCardContainer };
export type { Reference };
