const formatDate = (
  date?: string | number,
  part: 'date' | 'time' | 'both' = 'both',
) => {
  return date
    ? new Intl.DateTimeFormat('en-GB', {
        dateStyle: part === 'time' ? undefined : 'short',
        timeStyle: part === 'date' ? undefined : 'short',
      }).format(new Date(date))
    : ' --/--';
};

export default formatDate;
