import { OnboardingStepId } from 'components/types/onboarding';
import useProfile from 'hooks/useProfile';
import { FC, ReactElement } from 'react';
import ProfileView from 'views/ProfileView';
import HeaderContainer from 'containers/HeaderContainer';

interface ProfileContainerProps {
  step: OnboardingStepId;
  controls: ReactElement[];
}

const ProfileContainer: FC<ProfileContainerProps> = ({
  step,
  controls = [],
}) => {
  const {
    profile,
    hasValidDocuments,
    hasValidPersonalDetails,
    hasValidProfessionalDetails,
    hasValidReferences,
  } = useProfile();

  return (
    <ProfileView
      controls={controls}
      profile={profile}
      hasValidDocuments={hasValidDocuments}
      hasValidPersonalDetails={hasValidPersonalDetails}
      hasValidProfessionalDetails={hasValidProfessionalDetails}
      hasValidReferences={hasValidReferences}
      header={<HeaderContainer />}
      step={step}
    />
  );
};

export default ProfileContainer;
