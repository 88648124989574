import DocumentsControl, {
  AttachmentType,
  AttachmentTypeType,
} from 'components/profile/DocumentsControl';
import { UploadedItem } from 'contexts/MediaContext';
import { ProfileDTO } from 'generated/profile';
import useMedia from 'hooks/useMedia';
import useProfile from 'hooks/useProfile';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import ProfileContainer from './ProfileContainer';
import UploadHelpContent from 'components/uploadHelpContent/UploadHelpContent';
import { attachmentSpecs } from 'components/constants/AttachmentSpecs';

const DocumentsContainer: FC = () => {
  const { profile, saveProfile } = useProfile();
  const { downloadMedia } = useMedia();
  const [attachments, setAttachments] = useState<AttachmentType[]>([]);

  const buildAttachments = (profile: ProfileDTO) => {
    const attachments = attachmentSpecs
      ?.filter((att) => (att.show ? att.show?.(profile) : true))
      .reduce((acc: AttachmentType[], next) => {
        const attForCategory: AttachmentType = {
          ...next,
          minItems:
            next.minItems instanceof Function
              ? next.minItems(profile)
              : next.minItems,
          helpModal: next.uploadHelpContent && (
            <UploadHelpContent {...next.uploadHelpContent} />
          ),
          instances: [],
        };
        acc.push(attForCategory);
        profile?.attachments?.forEach((attachment) => {
          if (attachment.category === next.type) {
            attForCategory.instances.push({
              filename: attachment.filename,
              progress: 100,
            });
          }
        });
        return acc;
      }, []);
    return attachments;
  };

  const handleUploadComplete = ({ category, file }: UploadedItem) => {
    return saveProfile({
      ...profile!,
      attachments: [
        ...(profile?.attachments || []),
        { category, filename: file.name },
      ],
    });
  };
  const handleFileRemoved = (type: AttachmentTypeType, filename: string) => {
    saveProfile({
      ...profile!,
      attachments: profile?.attachments?.filter(
        (att) => att.category + att.filename !== type + filename,
      ),
    });
  };

  useEffect(() => {
    if (profile) {
      setAttachments(buildAttachments(profile));
    }
  }, [profile]);

  const navigateTo = useNavigate();

  const documentsControl = (
    <DocumentsControl
      onFileRemoved={handleFileRemoved}
      onUploadComplete={handleUploadComplete}
      onSubmit={async () => navigateTo('/profile/DECLARATION')}
      onDownload={(type, filename) =>
        downloadMedia(profile?.id!, type, filename)
      }
      attachments={attachments}
    />
  );

  return <ProfileContainer controls={[documentsControl]} step="DOCUMENTS" />;
};

export default DocumentsContainer;
