import React, { useEffect, useState } from 'react';
import { ValidationRule } from '.';
import { Box, Typography, useTheme } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

interface ChecklistProps {
  rules: ValidationRule[];
  password?: string;
  dataCy?: string;
}

const CheckList = React.memo(({ password, rules, dataCy }: ChecklistProps) => {
  const theme = useTheme();

  return (
    <>
      <div data-testid="checklist" data-cy="checklist-ruleset">
        {rules.map((rule, index) => {
          const isValid = !!password && rule.pattern.test(password);

          return (
            <Box key={`rule_${index}`}>
              <Typography
                fontSize={12}
                display={'flex'}
                gap={1}
                alignItems={'center'}
                color={
                  isValid ? theme.palette.success.main : theme.palette.grey[600]
                }
              >
                {isValid ? (
                  <CheckIcon
                    fontSize="small"
                    sx={{
                      color: theme.palette.success.main,
                    }}
                  />
                ) : (
                  <RadioButtonUncheckedIcon
                    fontSize="small"
                    sx={{
                      color: theme.palette.grey[300],
                    }}
                  />
                )}
                {rule.label}
              </Typography>
            </Box>
          );
        })}
      </div>
    </>
  );
});

CheckList.displayName = 'CheckList';

export { CheckList };
