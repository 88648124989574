import { TextField, TextFieldProps } from '@mui/material';
import {
  FC,
  FormEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ErrorCodes } from 'components/form';
import { FormContext } from 'components/form/formContext';

type InputBoxProps = TextFieldProps & {
  errorMessages?: Partial<Record<ErrorCodes, string>>;
  validationFunction?: (value: string) => Promise<string> | string;
};

const InputBox: FC<InputBoxProps> = ({
  validationFunction,
  onInput,
  errorMessages,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [errorMessage, setErrorMessage] = useState<string>();
  const { isSubmissionAttempted } = useContext(FormContext);

  const getErrorCode = () => {
    let errorKey: ErrorCodes | undefined = undefined;
    if (inputRef.current) {
      // can't use Object.keys here

      for (const key in inputRef.current.validity) {
        if (inputRef.current.validity[key as keyof ValidityState]) {
          errorKey = key as ErrorCodes;
          break;
        }
      }

      if (inputRef.current.validity.customError) {
        errorKey = inputRef.current.validationMessage as ErrorCodes;
      }

      return errorKey === 'valid' ? undefined : errorKey;
    }
  };

  const updateErrorMessage = useCallback(() => {
    const errorCode = getErrorCode();

    const isInvalid = isSubmissionAttempted && errorCode ? true : false;
    const errorText = isInvalid
      ? errorMessages?.[errorCode!] || inputRef.current?.validationMessage
      : '';

    setErrorMessage(errorText);
  }, [errorMessages, isSubmissionAttempted]);

  const validateInput = useCallback(async () => {
    if (inputRef.current) {
      const target = inputRef.current;

      // resetting the custom validity state
      target.setCustomValidity('');

      if (validationFunction && inputRef.current.validity.valid) {
        try {
          const message = await validationFunction(target.value);

          target.setCustomValidity(message);
        } catch (message: any) {
          target.setCustomValidity(message as string);
        }
      }
      updateErrorMessage();
    }
  }, [validationFunction, updateErrorMessage]);

  const handleInput = (e: FormEvent<HTMLInputElement>) => {
    validateInput();
    onInput && onInput(e);
  };

  useEffect(() => {
    // setting the inputRef to the input element
    inputRef.current = wrapperRef.current?.querySelector('input, textarea')!;
  }, [wrapperRef]);

  useEffect(() => {
    // running the validation on mount to set the initial validity state
    validateInput();
  }, [validateInput]);

  return (
    <TextField
      {...props}
      onInput={handleInput}
      error={isSubmissionAttempted && errorMessage ? true : false}
      helperText={errorMessage}
      ref={wrapperRef}
    />
  );
};

export { InputBox };
export type { InputBoxProps };
