import Button, { ButtonProps } from '@mui/material/Button';
import { Spinner } from 'components/Spinner/Spinner';
import { FormContext } from 'components/form/formContext';
import { useContext } from 'react';

interface SubmitButtonProps extends ButtonProps {
  children?: React.ReactNode;
}

const SubmitButton = ({ children = 'Save', ...rest }: SubmitButtonProps) => {
  const { isSubmitting } = useContext(FormContext);

  return (
    <Button
      type="submit"
      variant="contained"
      color="secondary"
      sx={{ minWidth: '120px' }}
      disabled={isSubmitting}
      {...rest}
    >
      {children}
      {isSubmitting && <Spinner marginLeft={1} />}
    </Button>
  );
};

export { SubmitButton };
