import { Box } from '@mui/material';
import { ProfessionalDetails } from 'components/fieldsets/ProfessionalDetails';
import { Form } from 'components/form';
import { FormErrorMessage } from 'components/form/formErrorMessage';
import { SectionCTAs } from 'components/sectionCTAs';
import { ProfileContextType } from 'contexts/ProfileContext';
import { ProfileDTO } from 'generated/profile';
import { FC } from 'react';

interface ProfessionalDetailsControlProps {
  onSubmit: (data: { [key: string]: string | string[] }) => Promise<ProfileDTO>;
  profile?: ProfileContextType;
  professions: any;
}

const ProfessionalDetailsControl: FC<ProfessionalDetailsControlProps> = ({
  profile,
  professions,
  onSubmit,
}) => {
  return (
    <Form
      onSubmit={(formData: FormData) => {
        const data: { [k: string]: string[] } = {};
        for (const entry of formData.entries()) {
          const [key, profile] = entry as [string, string];
          if (!data[key]) {
            data[key] = [];
          }
          data[key].push(profile);
        }
        return onSubmit(data);
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
        }}
      >
        <ProfessionalDetails value={profile} professions={professions} />
        <FormErrorMessage />
        <SectionCTAs
          previousUrl="/profile/PERSONAL"
          nextUrl={'/profile/REFERENCES'}
        />
      </Box>
    </Form>
  );
};

export default ProfessionalDetailsControl;
