import { Alert, Box, Collapse, Typography } from '@mui/material';
import { CheckBox } from 'components/checkBox';
import { Form } from 'components/form';
import { OptionGroup } from 'components/optionGroup';
import { SubmitButton } from 'components/submitButton';
import { PersonalDetailsDTO, ProfileDTO } from 'generated/profile';
import { FC, useState } from 'react';

interface DeclarationControlProps {
  profile: ProfileDTO;
  onSubmit: (data: any) => Promise<void>;
  onFileSelected: (file: File) => void;
  onRemove: (filename: string) => void;
}

const DeclarationControl: FC<DeclarationControlProps> = ({
  profile,
  onSubmit,
}) => {
  const [convictions, setConvictions] = useState(false);
  const [fitnessToPracticeProceedings, setFitnessToPracticeProceedings] =
    useState(false);

  return (
    <Form
      onSubmit={(formData: FormData) => {
        const data = Object.fromEntries(formData.entries());
        return onSubmit(data);
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Typography variant="h5">
          Working Time Regulations 1988 (&apos;The WTR Regulations&apos;)
        </Typography>
        <Typography variant="body2" fontSize="small">
          The WTR Regulations require Coben Healthcare Limited to limit your
          average weekly working time unless you agree with Coben Healthcare
          that the limit shall not apply to your contract with us.
        </Typography>
        <Typography variant="body2" fontSize="small">
          Either party may terminate the agreement (so that the time limit will
          apply to you) by giving the person at Coben Healthcare to whom you
          usually report four weeks written notice. Unless it is terminated in
          this way, this agreement shall remain in force until your contract
          with us terminates.
        </Typography>
        <Typography variant="body2" fontSize="small">
          Under the WTR Regulations, Coben Healthcare must keep records relating
          to your working time. This is the case whether or not you reach an
          agreement with Coben Healthcare about waiving time limits.
        </Typography>
        <Typography variant="body2" fontSize="small">
          Your signature shall constitute your agreement that this time limit on
          your working hours will not apply to your contract with Coben
          Healthcare and that your average working week may, therefore, exceed
          48 hours in any given period.
        </Typography>
        <OptionGroup
          name="opt_out_48h_limit"
          onChange={(e) => setConvictions(e.target.value === 'yes')}
          options={[
            { value: 'yes', label: 'Yes - I opt out' },
            { value: 'no', label: 'No' },
          ]}
          title="Opt out"
          description="Opt out of the 48h time limit?"
          defaultValue=""
          required
        />

        {profile.personalDetails?.payType ===
          PersonalDetailsDTO.payType.LTD && (
          <>
            <Typography variant="h5">
              Agency Workers Regulations 2010 (“AWR”)
            </Typography>
            <Typography variant="body2" fontSize="small">
              Under the AWR after 12 weeks in a given job, an agency worker will
              be entitled to the same terms and conditions relating to the
              duration of working time, night work, rest periods and rest
              breaks, annual leave and to be paid at the appropriate overtime
              rate as he or she would have received as a direct employee. For
              further information Agency Workers Regulations 2010: guidance for
              recruiters - GOV.UK (www.gov.uk)
            </Typography>
            <Typography variant="body2" fontSize="small">
              Opt Out under Regulation 32 of the Conduct of Employment Agencies
              Employment Businesses Regulations 2003 (“the Businesses
              Regulations”)
            </Typography>
            <Typography variant="body2" fontSize="small">
              You are engaged through a company to provide your services to the
              Client. and have agreed that, whilst you provide your services to
              the Client you shall opt out of the provisions of the 2003
              Regulations.
            </Typography>
            <OptionGroup
              name="opt_out_awr"
              onChange={(e) => setConvictions(e.target.value === 'yes')}
              options={[
                { value: 'yes', label: 'Yes - I opt out' },
                { value: 'no', label: 'No' },
              ]}
              title="Opt out"
              description="Opt out the provisions of the 2003 Regulations?"
              defaultValue=""
              required
            />

            <Typography variant="h6">
              Chapter 10 Part 2 of Income Tax (Earnings and Pensions) Act 2003
              (“IR35”)
            </Typography>
            <Typography variant="body2" fontSize="small">
              You warrant that whilst you are providing your services through
              the company to the Client, you are at all times operating outside
              of IR35.
            </Typography>
            <CheckBox
              label="I agree to the IR35 statement"
              name="ir35"
              required
            />
          </>
        )}

        <Typography variant="h5">Disclosure and Barring Service:</Typography>
        <Typography variant="body2" fontSize="small">
          All public and private organizations request that an Enhanced
          Disclosure check be obtained for all healthcare personnel. Please note
          that we have a duty to refer applicants to the relevant service where
          applicable, to comply with our obligations under The Safeguarding
          Vulnerable Groups Act Protecting Act (SVGA) 2006 and Vulnerable Groups
          (Scotland) Act 2007, Vulnerable Group (Northern Ireland) Order 2007.
        </Typography>
        <CheckBox
          label="I give Coben Healthcare permission to check DBS certificates portability status online"
          name="check_dbs_portability"
          required
        />
        <CheckBox
          label="I agree to Coben Healthcare providing details of my DBS on a client's request."
          name="dbs_details_provided"
          required
        />

        <Typography variant="h5">
          Rehabilitation of Offenders Act 1974
        </Typography>
        <Typography variant="body2" fontSize="small">
          Applicants for Healthcare positions are exempt from the Rehabilitation
          of Offenders Act 1974. You are required to declare prosecutions or
          convictions, including those considered &apos;spent&apos; under this
          Act. A criminal conviction applies to all convictions, cautions,
          reprimands, and final warnings. This includes minor misdemeanors, such
          as: road traffic offenses (speeding, parking fines) except where the
          matter has been dealt with by way of a &apos;fixed penalty
          notice&apos; as such matters do not constitute a criminal conviction.
        </Typography>

        <OptionGroup
          name="convictions"
          onChange={(e) => setConvictions(e.target.value === 'yes')}
          options={[
            { value: 'no', label: 'No - no convictions' },
            { value: 'yes', label: 'Yes' },
          ]}
          title="Convictions"
          description="Have you been convicted of a criminal offence, been bound over, cautioned or are you currently the subject of any police investigations, which might lead to a conviction, an order binding you over or a caution in the UK or any other country?"
          defaultValue=""
          required
        />
        <Collapse in={convictions}>
          <Alert severity="info">
            Our compliance team will be in touch to obtain full details and
            advise you of our policy for the Rehabilitation of Offenders Act
            1978
          </Alert>
        </Collapse>

        <OptionGroup
          name="fitness_to_practice_proceedings"
          onChange={(e) =>
            setFitnessToPracticeProceedings(e.target.value === 'yes')
          }
          options={[
            { value: 'no', label: 'No - I am fit to practice' },
            { value: 'yes', label: 'Yes - I am subject to proceedings' },
          ]}
          title="Fitness to Practice"
          description="Have you been or are you currently subject to any fitness to practice proceedings by an appropriate licensing or regulatory body in the UK or any other country?"
          defaultValue=""
          required
        />
        <Collapse in={fitnessToPracticeProceedings}>
          <Alert severity="info">
            Our compliance team will be in touch to obtain full details and
            advise you of our policy around FTP proceedings
          </Alert>
        </Collapse>

        <SubmitButton variant="contained" color="secondary">
          Download declaration
        </SubmitButton>
      </Box>
    </Form>
  );
};

export default DeclarationControl;
