import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
  palette: {
    primary: {
      main: '#273245',
      light: 'hsl(218, 28.80%, 80.00%)',
    },
    secondary: {
      main: '#ffc445',
    },
  },
  components: {
    MuiContainer: {
      defaultProps: {
        sx: {
          paddingX: {
            xs: 0,
          },
        },
      },
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});

theme = responsiveFontSizes(theme);

export { theme };
