import { Auth } from 'aws-amplify';
import { CandidateProfileClient } from 'generated/profile';
import { MediaServiceClient } from 'generated/media';
import useConfig from 'hooks/useConfig';
import { createContext, useEffect, useState } from 'react';
import { Config } from './ConfigContext';
import { DeclarationServiceClient } from 'generated/declaration';
import { useAuth } from 'hooks/useAuth';

const buildApiClients = (config: Config) => ({
  profileClient: new CandidateProfileClient({
    BASE: `${config.apiBase}/profile-service`,
    TOKEN: () =>
      Auth.currentAuthenticatedUser().then(
        (userData) => userData.signInUserSession.accessToken.jwtToken,
      ),
  }),
  mediaClient: new MediaServiceClient({
    BASE: `${config.apiBase}/media-service`,
    TOKEN: () =>
      Auth.currentAuthenticatedUser().then(
        (userData) => userData.signInUserSession.accessToken.jwtToken,
      ),
  }),
  declarationClient: new DeclarationServiceClient({
    BASE: `${config.apiBase}/declaration-service`,
    TOKEN: () =>
      Auth.currentAuthenticatedUser().then(
        (userData) => userData.signInUserSession.accessToken.jwtToken,
      ),
  }),
});

const DEFAULT_CLIENTS = {
  profileClient: null,
  mediaClient: null,
  declarationClient: null,
} as const;

const ApiClientsContext = createContext<{
  profileClient: CandidateProfileClient | null;
  mediaClient: MediaServiceClient | null;
  declarationClient: DeclarationServiceClient | null;
}>(DEFAULT_CLIENTS);

type ApiClientsProviderProps = {
  children: React.ReactNode;
};

const ApiClientsProvider = ({ children }: ApiClientsProviderProps) => {
  const config = useConfig();
  const { user } = useAuth();
  const [clients, setClients] = useState<{
    profileClient: CandidateProfileClient | null;
    mediaClient: MediaServiceClient | null;
    declarationClient: DeclarationServiceClient | null;
  }>(DEFAULT_CLIENTS);

  useEffect(() => {
    if (config && user) {
      setClients(buildApiClients(config));
    }
    if (!user) {
      setClients(DEFAULT_CLIENTS);
    }
  }, [config, user]);

  return (
    <ApiClientsContext.Provider value={clients}>
      {children}
    </ApiClientsContext.Provider>
  );
};

export { ApiClientsContext, ApiClientsProvider };
