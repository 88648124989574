import { AttachmentType } from 'components/profile/DocumentsControl';
import { ProfileDTO } from 'generated/profile';

export interface AttchmentSpec
  extends Omit<AttachmentType, 'instances' | 'minItems'> {
  show?: (profile: ProfileDTO) => boolean;
  isValid?: (profile: ProfileDTO) => boolean;
  minItems: number | ((profile: ProfileDTO) => number);
  uploadHelpContent?: {
    title: string;
    description: string;
    rules?: string[];
    closingStatement?: string;
  };
}

const requirementsMet = (spec: AttchmentSpec, profile: ProfileDTO) => {
  if (spec.show && !spec.show(profile)) return true;
  const minItems =
    spec.minItems instanceof Function ? spec.minItems(profile) : spec.minItems;
  const attachmentCount =
    profile?.attachments?.filter((att) => att.category === spec.type)?.length ||
    0;

  return attachmentCount >= minItems;
};

export const attachmentSpecs: AttchmentSpec[] = [
  {
    type: 'CV',
    minItems: 1,
    maxItems: 1,
    uploadHelpContent: {
      title: 'Please upload your CV',
      description:
        'Ensure your CV is inclusive and leaves no gaps greater than 2 weeks. Follow these steps for an effective submission:',
      rules: [
        'Include your current or latest role, highlighting responsibilities and acquired skills.',
        'Account for any gaps by providing information on personal projects, voluntary work, or training courses.',
        'Provide organization names, job titles, dates of employment, and key responsibilities for a thorough representation.',
        'Showcase skills, software proficiencies, certifications, and qualifications applicable to the position.',
        'Include noteworthy accomplishments and awards to highlight your achievements.',
      ],
    },
    isValid: function (profile) {
      return requirementsMet(this, profile);
    },
  },
  {
    type: 'DBS',
    minItems: 1,
    maxItems: 1,
    uploadHelpContent: {
      title: 'Please upload your DBS',
      description:
        'Must be dated within the last 3 years. Please note some of our clients require a DBS dated between 6 month and 1 year. Your Recruitment Consultant will make you aware of these clients when discussing your work requirements',
      rules: [
        'DBS must be in date and issued within the last 36 months',
        'If you have a DBS update service subscription, please upload your DBS certificate and your subscription number',
        'Your DBS must be in PDF format',
        'Your DBS must be less than 10MB',
      ],
    },
    isValid: function (profile) {
      return requirementsMet(this, profile);
    },
  },
  {
    type: 'Indemnity',
    minItems: (profile) =>
      ['gp', 'nurse'].includes(profile?.professionalDetails?.profession!)
        ? 1
        : 0,
    maxItems: 2,
    uploadHelpContent: {
      title: 'Indemnity Requirements: Ensuring Proper Coverage',
      description:
        'To ensure appropriate coverage, please adhere to the following indemnity guidelines:',
      rules: [
        'State Backed Indemnity: While you may be covered by state-backed indemnity, certain positions may require mandatory Professional Indemnity.',
        'Recommended Medical Insurance: Regardless of your position, we strongly advise doctors to obtain and maintain Medical Insurance.',
        'Mandatory for Registered Nurses: Registered Nurses are required to hold Medical Insurance as part of their Professional Registration.',
        'Proof of Insurance: Please provide evidence of your current medical insurance certificate as required.',
      ],
    },
    isValid: function (profile) {
      return requirementsMet(this, profile);
    },
  },
  {
    type: 'Passport',
    minItems: 1,
    maxItems: 1,
    isValid: function (profile) {
      return requirementsMet(this, profile);
    },
    uploadHelpContent: {
      title: 'Please upload your passport',
      description:
        'Please follow these guidelines to ensure a successful passport verification process:',
      rules: [
        'Make sure your passport copy is clear and easily readable.',
        'Ensure your passport is in date and that the name and date of birth match your other RTW and compliance documents.',
        'You will receive a link from our Compliance Team to verify your passport. The verification will be conducted through U-comply, an accredited and certified company we use. This process adheres to the latest Home Office guidance for Right to Work Checks.',
        'Keep your mobile phone handy as the passport verification will involve facial recognition.',
        'The verification process will use facial recognition technology to verify your passport. Follow the instructions provided to complete this step.',
      ],
    },
  },
  {
    type: 'Visa',
    minItems: 0,
    maxItems: 1,
    uploadHelpContent: {
      title: 'Visa Requirements: Ensuring Valid Documentation',
      description: 'To meet visa requirements, please follow these guidelines:',
      rules: [
        'Overseas Passport Holders: For overseas passport holders, we require an in-date visa. Please provide a clear and legible copy of your visa.',
        'Validity and Consistency: Ensure that your visa is in date and that your name and date of birth match the information on all other right to work documentation.',
        "Biometric Residence Card/Permit: If you have a Biometric Residence Card/Permit, please send us your 'Share Code'. This can be done through the Apply to the EU Settlement Scheme website on GOV.UK.",
        "Share Code and Date of Birth: Once you have obtained the Share Code, send us the 'Share Code and your Date of Birth'. This is necessary for us to conduct relevant checks on the Government Website.",
      ],
    },
    show: (profile) => !!(profile?.personalDetails?.nationality !== 'BRITISH'),
    isValid: function (profile) {
      return requirementsMet(this, profile);
    },
  },
  {
    type: 'POQ',
    minItems: 0,
    maxItems: 5,
    isValid: function (profile) {
      return requirementsMet(this, profile);
    },
    uploadHelpContent: {
      title: 'Please upload your qualifications',
      description:
        'We require all basic and higher qualifications and training that appear on your CV.',
    },
  },
  {
    type: 'Immunisation',
    minItems: 0,
    maxItems: 10,
    isValid: function (profile) {
      return requirementsMet(this, profile);
    },
    uploadHelpContent: {
      title: 'Immunisation Requirements',
      description: 'We require MMR, TB, Varicella, Hep B on a pathology report',
    },
  },
  {
    type: 'POT',
    minItems: 0,
    maxItems: 10,
    isValid: function (profile) {
      return requirementsMet(this, profile);
    },
    uploadHelpContent: {
      title: 'Please upload your proof of training',
      description:
        'We require all basic and higher qualifications and training that appear on your CV.',
      rules: [
        'Ensure you have completed Basic and Advanced Life Support training, SOVA Level 3, and SOLVC Level 3 certifications.',
        'Submit clear and legible copies of your training certificates as proof of completion.',
        'Out of Hours Work: If you are interested in out of hours work, additional training requirements may apply. Our compliance team will discuss these with you when relevant.',
      ],
      closingStatement:
        'If additional training is required, we can provide you with free links to complete the necessary courses. Please contact our compliance team for more information.',
    },
  },
  {
    type: 'OTHER',
    minItems: 0,
    maxItems: 99,
    isValid: function (profile) {
      return requirementsMet(this, profile);
    },
    uploadHelpContent: {
      title: 'Please upload any other documents',
      description: 'Please upload any other documents that you wish to share.',
    },
  },
];
