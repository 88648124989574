import { ErrorCodes } from '../form/types';

const customErrorMessages: Partial<Record<ErrorCodes, string>> = {
  valueMissing: 'This field is required 🫣',
  tooShort: 'This field is too short 🫣',
  tooLong: 'This field is too long 🫣',
  patternMismatch: 'This field is invalid 🫣',
  typeMismatch: 'This field is invalid 🫣',
  rangeOverflow: 'This field is too large 🫣',
  rangeUnderflow: 'This field is too small 🫣',
  stepMismatch: 'This field is invalid 🫣',
  badInput: 'This field is invalid 🫣',
  customError: 'This field is invalid 🫣',
};

export { customErrorMessages };
