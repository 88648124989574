/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MediaUrl } from '../models/MediaUrl';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdminService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Lists files for profile
     * @param userId Id of the user
     * @param profileId Id of the profile
     * @returns any Success
     * @throws ApiError
     */
    public getAdminMediaByCategory(
        userId: string,
        profileId: string,
    ): CancelablePromise<Array<{
        category?: string;
        filename?: string;
    }>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/{userId}/profiles/{profileId}',
            path: {
                'userId': userId,
                'profileId': profileId,
            },
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Get the download url for the file
     * @param category Category of the file
     * @param filename Filename
     * @returns MediaUrl Success
     * @throws ApiError
     */
    public getAdminMediaByFilename(
        category: string,
        filename: string,
    ): CancelablePromise<MediaUrl> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/{userId}/profiles/{profileId}/{category}/{filename}',
            path: {
                'category': category,
                'filename': filename,
            },
            errors: {
                404: `Not found`,
            },
        });
    }

}
