import { useAuth } from 'hooks/useAuth';
import useProfile from 'hooks/useProfile';
import { FC } from 'react';
import HomeView from 'views/HomeView';
import HeaderContainer from './HeaderContainer';
import useVerification from 'hooks/useVerification';
import {
  ProfileDTO,
  VerificationDTO,
  VerificationStateDTO,
} from 'generated/profile';
import { VerificationStepStatus } from 'components/types/onboarding';
import { VerificationStatus } from 'components/types/verification';
import HomeViewAnonymoutContent from 'components/content/HomeViewAnonymoutContent';
import LoggedInContentNoProfile from 'components/content/HomeViewNoProfileContent';
import HomeViewProfileEditContent from 'components/content/HomeViewProfileEditContent';
import HomeViewVerifiedContent from 'components/content/HomeViewVerifiedContent';
import HomeViewActionRequiredContent from 'components/content/HomeViewActionRequiredContent';
import HomeViewInVerificationContent from 'components/content/HomeViewInVerificationContent';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Paper,
  Typography,
} from '@mui/material';
import { Spinner } from 'components/Spinner/Spinner';

import { whyJoinCoben } from 'components/constants/whyJoinCoben';
import VerificationStatusControl from 'components/profile/VerificationStatusControl';
import ProfileStepper from 'components/ProfileStepper';
import formatDate from 'helpers/dateFormat';

const MAP: { [key: string]: VerificationStepStatus } = {
  [VerificationStateDTO.PENDING]: 'PENDING',
  [VerificationStateDTO.IN_PROGRESS]: 'IN_PROGRESS',
  [VerificationStateDTO.VERIFIED]: 'COMPLETED',
  [VerificationStateDTO.ACTION_REQUIRED]: 'ACTION_REQUIRED',
};

const Loading = () => {
  return (
    <Box
      display={'flex'}
      gap={2}
      justifyContent={'center'}
      alignItems={'center'}
      height={'100%'}
    >
      <Spinner />
      <Typography variant={'h5'}>Loading...</Typography>
    </Box>
  );
};

const computeSectionState = (
  verificationStates: any[] = [],
  expectedCount: number,
): VerificationStepStatus => {
  if (
    verificationStates.some(
      (state) => state === VerificationStateDTO.ACTION_REQUIRED,
    )
  ) {
    return 'ACTION_REQUIRED';
  }
  if (
    verificationStates.length == expectedCount &&
    verificationStates.every((state) => state === 'COMPLETED')
  ) {
    return 'COMPLETED';
  }
  if (
    verificationStates.some(
      (state) => state === VerificationStateDTO.IN_PROGRESS,
    )
  ) {
    return 'IN_PROGRESS';
  }
  return 'PENDING';
};

const mapVerification = (
  verification: VerificationDTO,
  profile?: ProfileDTO | null,
): VerificationStatus => {
  const items = verification.items.reduce((acc, item) => {
    acc[item.entityId.section] = {
      states: [
        ...(acc[item.entityId.section]?.states || []),
        MAP[item.state || 'PENDING'],
      ],
      logs: item.comments.map((comment) => (
        <Typography component={'p'} variant="body2" key={comment.createdAt}>
          {formatDate(comment.createdAt!)}: <strong>{comment.comment}</strong>
        </Typography>
      )),
    };
    return acc;
  }, {} as any);

  return {
    PERSONAL: {
      status: items['personal']?.states[0] || 'PENDING',
      logs: items['personal']?.logs,
    },
    PROFESSIONAL: {
      status: items['professional']?.states[0] || 'PENDING',
      logs: items['professional']?.logs,
    },
    REFERENCES: {
      status: computeSectionState(
        items['reference']?.states,
        profile?.references?.length || 0,
      ),
      logs: items['reference']?.logs,
    },
    DOCUMENTS: {
      status: computeSectionState(
        items['attachment']?.states,
        profile?.attachments?.length || 0,
      ),
      logs: items['attachment']?.logs,
    },
    DECLARATION: {
      status: items['declaration']?.states[0] || 'PENDING',
      logs: items['declaration']?.logs,
    },
  };
};

const HomeContainer: FC = () => {
  const { user } = useAuth();
  const {
    profile,
    createProfile,
    hasValidDocuments,
    hasValidPersonalDetails,
    hasValidProfessionalDetails,
    hasValidReferences,
  } = useProfile();
  const { verification } = useVerification();

  const handleUpdateProfile = () => {
    // TODO: Handle new profile version
    createProfile({ email: user?.username! });
  };

  const handleEditProfile = () => {
    // TODO: Handle edit profile
  };

  const verificationStatus = verification
    ? mapVerification(verification, profile)
    : undefined;

  const MainContent = () => {
    if (user === undefined) {
      return <Loading />;
    }

    if (user === null) {
      return <HomeViewAnonymoutContent />;
    }

    if (profile === undefined) {
      return <Loading />;
    }

    if (profile === null) {
      return (
        <LoggedInContentNoProfile
          onGetStarted={() => createProfile({ email: user?.username! })}
        />
      );
    }

    if (!profile.submittedAt) {
      return <HomeViewProfileEditContent profile={profile} />;
    }

    if (
      !verification ||
      verification.state === VerificationStateDTO.PENDING ||
      verification.state === VerificationStateDTO.IN_PROGRESS
    ) {
      return <HomeViewInVerificationContent profile={profile} />;
    }

    if (verification.state === VerificationStateDTO.ACTION_REQUIRED) {
      return <HomeViewActionRequiredContent profile={profile} />;
    }

    if (verification.state === VerificationStateDTO.VERIFIED) {
      return (
        <HomeViewVerifiedContent
          profile={profile}
          handleUpdateProfile={handleUpdateProfile}
        />
      );
    }

    return null;
  };

  const SideContent = () => {
    if (user === undefined || profile === undefined) return null;

    return (
      <Box
        sx={{
          minWidth: { xs: '100%', sm: '400px' },
        }}
      >
        {profile === null && (
          <Paper
            sx={(theme) => ({
              padding: 3,
              maxWidth: '600px',
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            })}
          >
            <Typography variant="h5">
              <strong>Why Join Coben Healthcare?</strong>
            </Typography>
            {whyJoinCoben}
          </Paper>
        )}
        {!!user && !!profile && !profile.submittedAt && (
          <Card sx={{ maxWidth: '600px' }}>
            <CardHeader title="Profile Progress" />
            <CardContent>
              <ProfileStepper
                activeStep={-1}
                hasValidPersonalDetails={hasValidPersonalDetails}
                hasValidProfessionalDetails={hasValidProfessionalDetails}
                hasValidDocuments={hasValidDocuments}
                hasValidReferences={hasValidReferences}
                orientation="vertical"
              />
            </CardContent>
          </Card>
        )}
        {!!user && !!profile?.submittedAt && (
          <Card sx={{ maxWidth: '600px' }}>
            <CardHeader title="Verification Progress" />
            <CardContent>
              <VerificationStatusControl
                verificationStatus={verificationStatus}
                showLogs
              />
            </CardContent>
          </Card>
        )}
      </Box>
    );
  };

  return (
    <HomeView header={<HeaderContainer />}>
      <Box flexGrow={1}>
        <MainContent />
      </Box>
      <SideContent />
    </HomeView>
  );
};

export default HomeContainer;
