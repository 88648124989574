/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum VerificationStateDTO {
    PENDING = 'PENDING',
    IN_PROGRESS = 'IN_PROGRESS',
    ACTION_REQUIRED = 'ACTION_REQUIRED',
    VERIFIED = 'VERIFIED',
}
