import { InputBox } from 'components/inputBox';
import UserView from './UserView';
import { Form } from 'components/form';
import { Avatar, Box, Button, Link, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { FC } from 'react';

interface VerificationViewProps {
  email?: string;
  verificationError?: {
    email?: string;
    code?: string;
    other?: string;
  };
  onSubmit: (code: string) => Promise<void>;
  onResendCodeClick: () => void;
}

const VerificationView: FC<VerificationViewProps> = ({
  email,
  verificationError,
  onSubmit,
  onResendCodeClick,
}) => {
  const handleSubmit = (formData: FormData) => {
    return onSubmit(formData.get('code') as string);
  };

  return (
    <UserView>
      <Avatar
        className="avatar"
        sx={(theme) => ({ bgcolor: theme.palette.secondary.main })}
      >
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Verify
      </Typography>
      <Form onSubmit={handleSubmit}>
        <Box display={'flex'} flexDirection={'column'} gap={4}>
          <Typography variant="body2">
            <p>
              Please enter the verification code you received in your email.
              This code is used to confirm your identity and ensure the security
              of your account.
            </p>
            <p>
              If you haven&quot;t received a verification code, please check
              your spam or junk folder. It may take a few minutes for the email
              to arrive. You can also click the &quot;Resend Code&quot; button
              to request a new verification code.
            </p>
          </Typography>
          <div>
            <InputBox
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              helperText={verificationError?.email}
              error={!!verificationError?.email}
              defaultValue={email}
              autoFocus
            />
            <InputBox
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="code"
              label="Code"
              name="code"
              autoComplete="code"
              helperText={
                verificationError?.code ||
                'Enter the verification code you received by email.'
              }
              error={!!verificationError?.code}
              autoFocus
            />
          </div>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className="submit"
            sx={{
              width: '200px',
              margin: 'auto',
            }}
          >
            Verify
          </Button>
          <Box width="100%" display="flex" justifyContent="center">
            <Typography variant="body2">
              Didn&quot;t receive a code?
              <Link onClick={onResendCodeClick} marginLeft={2}>
                Resend code
              </Link>
            </Typography>
          </Box>
        </Box>
      </Form>
    </UserView>
  );
};

export default VerificationView;
