import React from 'react';
import Layout from 'components/Layout';
import { Backdrop, Box, CircularProgress } from '@mui/material';
import Footer from 'components/Footer';

export interface UserViewProps {
  loading?: boolean;
  children: React.ReactNode;
}

const UserView: React.FC<UserViewProps> = ({ loading, children }) => {
  return (
    <Layout footer={<Footer />} showBackgroundImage={false}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!!loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box sx={{ display: 'flex', flexGrow: 1, height: '100%' }}>
        <Box
          sx={{
            display: {
              xs: 'none',
              sm: 'flex',
            },
            flexGrow: 1,
            backgroundImage: 'url(/doctor.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Box
          display="flex"
          flexDirection="column"
          sx={(theme) => ({
            flexBasis: {
              xs: 'auto',
              sm: '40%',
            },
            padding: {
              xs: theme.spacing(8, 1),
              sm: theme.spacing(8, 4),
            },
            display: 'flex',
            gap: 2,
            flexDirection: 'column',
            alignItems: 'center',
            overflow: 'auto',
            ['form']: {
              maxWidth: '520px',
            },
          })}
        >
          {children}
        </Box>
      </Box>
    </Layout>
  );
};

export default UserView;
