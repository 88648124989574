/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type VerificationEntityIdDTO = {
    section: VerificationEntityIdDTO.section;
    /**
     * Id of the entity, this is used in case a section has multiple
     * entities, such as attachments and references
     */
    id?: string;
};

export namespace VerificationEntityIdDTO {

    export enum section {
        PERSONAL = 'personal',
        PROFESSIONAL = 'professional',
        ATTACHMENT = 'attachment',
        REFERENCE = 'reference',
        DECLARATION = 'declaration',
    }


}

