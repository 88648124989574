import DeclarationControl from 'components/profile/DeclarationControl';
import SubmissionControl from 'components/profile/SubmissionControl';
import useApiClients from 'hooks/useApiClients';
import useMedia from 'hooks/useMedia';
import useProfile from 'hooks/useProfile';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import ProfileContainer from './ProfileContainer';

const Declarationcontainer: FC = () => {
  const { declarationClient } = useApiClients();
  const { profile, saveProfile, submitProfile } = useProfile();
  const { uploadMedia } = useMedia();
  const [uploadProgress, setUploadProgress] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    setUploadProgress(profile?.declaration?.signedDeclaration ? 100 : 0);
  }, [profile]);

  const handleDownloadDeclarationClick = (declaration: any) =>
    saveProfile({
      ...profile!,
      declaration: {
        convictions: declaration.convictions,
        fitnessToPractice: declaration.fitness_to_practice_proceedings,
        optOut48hLimit: declaration.opt_out_48h_limit,
        optOutAwr: declaration.opt_out_awr,
        declarationCheck: Math.floor(Math.random() * 899999 + 100000),
      },
    })
      .then(() => declarationClient!.candidate.getDeclarationJson())
      .then((data) => {
        const url = `data:application/pdf;base64,${data.data}`;
        const link = document.createElement('a');
        link.href = url;
        link.download = 'declaration.pdf';
        link.click();
      });

  const handleUploadSignedDeclaration = (file: File) => {
    uploadMedia(profile!.id!, 'DECLARATION', file, (progress) =>
      setUploadProgress(progress),
    ).then(() => {
      saveProfile({
        ...profile!,
        declaration: {
          ...profile!.declaration,
          signedDeclaration: file.name,
        },
      });
    });
  };

  const handleSubmitForVerification = () => {
    return submitProfile().then((profile) => {
      navigate('/');
    });
  };

  return (
    <ProfileContainer
      step="DECLARATION"
      controls={[
        <DeclarationControl
          profile={profile!}
          key="DeclarationControl"
          onFileSelected={() => {}}
          onRemove={() => {}}
          onSubmit={handleDownloadDeclarationClick}
        />,
        <SubmissionControl
          profile={profile!}
          key="SubmissionControl"
          uploadProgress={uploadProgress}
          handleUploadSignedDeclaration={handleUploadSignedDeclaration}
          handleSubmitForVerification={handleSubmitForVerification}
        />,
      ]}
    />
  );
};

export default Declarationcontainer;
