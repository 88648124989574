import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { User } from 'components/types/user';
import Declarationcontainer from 'containers/DeclarationContainer';
import DocumentsContainer from 'containers/DocumentsContainer';
import { ForgottenPasswordContainer } from 'containers/ForgottenPasswordContainer';
import HomeContainer from 'containers/HomeContainer';
import LoginContainer from 'containers/LoginContainer';
import PersonalDetailsContainer from 'containers/PersonalDetailsContainer';
import ProfessionalContainer from 'containers/ProfessionalContainer';
import ReferencesContainer from 'containers/ReferencesContainer';
import RegistrationContainer from 'containers/RegistrationContainer';
import VerificationContainer from 'containers/VerificationContainer';
import { ProfileProvider } from 'contexts/ProfileContext';
import { VerificationProvider } from 'contexts/VerificationContext';
import { useAuth } from 'hooks/useAuth';
import { FC } from 'react';
import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from 'react-router-dom';

const redirectAnonymousUser = (user?: User | null) => {
  if (user === null) throw redirect('/login');
  return null;
};

const App: FC = () => {
  const { logout, user } = useAuth();
  const router = createBrowserRouter([
    { path: '/', Component: HomeContainer },
    {
      path: '/profile/PERSONAL',
      Component: PersonalDetailsContainer,
      loader: () => redirectAnonymousUser(user),
    },
    {
      path: '/profile/PROFESSIONAL',
      Component: ProfessionalContainer,
      loader: () => redirectAnonymousUser(user),
    },
    {
      path: '/profile/DOCUMENTS',
      Component: DocumentsContainer,
      loader: () => redirectAnonymousUser(user),
    },
    {
      path: '/profile/REFERENCES',
      Component: ReferencesContainer,
      loader: () => redirectAnonymousUser(user),
    },
    {
      path: '/profile/DECLARATION',
      Component: Declarationcontainer,
      loader: () => redirectAnonymousUser(user),
    },
    { path: '/login', Component: LoginContainer },
    { path: '/signup', Component: RegistrationContainer },
    { path: '/verify', Component: VerificationContainer },
    {
      path: '/logout',
      loader: () => {
        return logout()
          .then(() => {
            redirect('/');
          })
          .then(() => {
            return null;
          });
      },
    },
    {
      path: '/forgotten_password',
      Component: ForgottenPasswordContainer,
    },
    {
      path: '*',
      Component: () => (
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          height={'100vh'}
        >
          <Typography variant="h1">404</Typography>
        </Box>
      ),
    },
  ]);

  return (
    <div className="App">
      <ProfileProvider>
        <VerificationProvider>
          <RouterProvider router={router} />
        </VerificationProvider>
      </ProfileProvider>
    </div>
  );
};

export default App;
