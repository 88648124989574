import React, { FormEvent } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import UserView from './UserView';
import { Form } from 'components/form';
import { LoginError } from 'containers/LoginContainer';
import { InputBox } from 'components/inputBox';
import { Password } from 'components/password';
import { FormErrorMessage } from 'components/form/formErrorMessage';

export interface LoginViewProps {
  email?: String;
  password?: String;
  loginError?: LoginError | null;
  loading?: boolean;
  onSubmit: (email: string, password: string) => Promise<void>;
  onAlertClose?: () => void;
}

const LoginView: React.FC<LoginViewProps> = ({
  email,
  password,
  loginError,
  loading,
  onSubmit,
  onAlertClose,
}) => {
  const handleSubmit = (formData: FormData) => {
    return onSubmit(
      formData.get('email') as string,
      formData.get('password') as string,
    );
  };

  const handleFieldChanged = (e: FormEvent<HTMLFormElement>) => {};

  return (
    <UserView loading={loading}>
      <Avatar
        className="avatar"
        sx={(theme) => ({ bgcolor: theme.palette.secondary.main })}
      >
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Log in
      </Typography>
      <Form onChange={handleFieldChanged} onSubmit={handleSubmit}>
        <Box display={'flex'} flexDirection={'column'} gap={4}>
          <Box>
            <InputBox
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              helperText={loginError?.email}
              error={!!loginError?.email}
              defaultValue={email}
              autoFocus
            />

            <Password
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              id="password"
              autoComplete="current-password"
              helperText={loginError?.password}
              defaultValue={password}
              error={!!loginError?.password}
            />
          </Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className="submit"
            sx={{
              mb: 2,
            }}
          >
            Log In
          </Button>
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            flexDirection={{
              xs: 'column',
              sm: 'row',
            }}
          >
            <Link component={RouterLink} to="/forgotten_password">
              <Typography variant="body2">Forgot password?</Typography>
            </Link>
            <Link component={RouterLink} to="/signup">
              <Typography variant="body2">Sign Up</Typography>
            </Link>
          </Box>
        </Box>
        <FormErrorMessage />
      </Form>
    </UserView>
  );
};

LoginView.displayName = 'LoginView';

export default LoginView;
