import { attachmentSpecs } from 'components/constants/AttachmentSpecs';
import { ProfileDTO } from 'generated/profile';
import useApiClients from 'hooks/useApiClients';
import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react';

export type ProfileContextType = {
  profile?: ProfileDTO | null;
  fetchProfile: () => void;
  saveProfile: (profile: ProfileDTO) => Promise<ProfileDTO>;
  createProfile: (profile: ProfileDTO) => void;
  submitProfile: () => Promise<ProfileDTO>;
  hasValidPersonalDetails: boolean;
  hasValidProfessionalDetails: boolean;
  hasValidDocuments: boolean;
  hasValidReferences: boolean;
};

const ProfileContext = createContext<ProfileContextType>({
  fetchProfile: () => {},
  saveProfile: () => Promise.resolve({} as ProfileDTO),
  createProfile: () => {},
  submitProfile: () => Promise.resolve({} as ProfileDTO),
  hasValidPersonalDetails: true,
  hasValidProfessionalDetails: true,
  hasValidDocuments: true,
  hasValidReferences: true,
});

interface ProfileProviderProps {
  children: ReactNode;
}

const ProfileProvider: FC<ProfileProviderProps> = ({ children }) => {
  const [profile, setProfile] = useState<ProfileDTO | null | undefined>(
    undefined,
  );
  const { profileClient } = useApiClients();
  const [hasValidPersonalDetails, setHasValidPersonalDetails] = useState(false);
  const [hasValidProfessionalDetails, setHasValidProfessionalDetails] =
    useState(false);
  const [hasValidDocuments, setHasValidDocuments] = useState(false);
  const [hasValidReferences, setHasValidReferences] = useState(false);

  const fetchProfile = () => {
    if (!profileClient) {
      return;
    }

    return profileClient.candidate
      .getSelfProfile()
      .then((profile) => setProfile(profile))
      .catch((error) => {
        if (error.status === 404) {
          setProfile(null);
        } else {
          console.error('Error fetching profile:', error);
        }
      });
  };

  const saveProfile = (profileToSave: ProfileDTO) => {
    if (!profileClient) {
      console.warn('Profile client not available');
      throw new Error('Profile client not available');
    }

    return profileClient.candidate
      .updateSelfProfile(profileToSave)
      .then((profile) => {
        setProfile(profile);

        return profile;
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  const createProfile = useCallback(
    (profileToCreate: ProfileDTO) => {
      if (!profileClient) {
        return;
      }

      return profileClient.candidate
        .createSelfProfile(profileToCreate)
        .then((profile) => setProfile(profile));
    },
    [profileClient],
  );

  const submitProfile = useCallback(() => {
    if (!profileClient) {
      console.warn('Profile client not available');
      throw new Error('Profile client not available');
    }

    return profileClient.candidate.postSubmitProfile().then((profile) => {
      setProfile(profile);
      return profile;
    });
  }, [profileClient]);

  useEffect(() => {
    if (profileClient) {
      setProfile(undefined);
      fetchProfile();
    }
  }, [profileClient]);

  useEffect(() => {
    if (profile) {
      setHasValidPersonalDetails(!!profile.personalDetails?.forename);
      setHasValidProfessionalDetails(!!profile.professionalDetails?.profession);
      setHasValidReferences(
        profile.references?.length
          ? profile.references?.length > 1 // at least 2 references
          : false,
      );
      setHasValidDocuments(
        attachmentSpecs.every((a) => {
          return a.isValid ? a.isValid?.(profile) : true;
        }),
      );
    }
  }, [profile]);

  return (
    <ProfileContext.Provider
      value={{
        profile,
        fetchProfile,
        saveProfile,
        createProfile,
        submitProfile,
        hasValidPersonalDetails,
        hasValidProfessionalDetails,
        hasValidDocuments,
        hasValidReferences,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export { ProfileContext, ProfileProvider };
