import { FC, useState } from 'react';
import { Password, PasswordProps } from 'components/password';
import {
  LENGTH_REGEX,
  LOWERCASE_REGEX,
  SPECIAL_CHARS_REGEX,
  UPPERCASE_REGEX,
} from 'components/constants/passwordValidationRegExp';
import { ValidationRule } from 'components/password';

const NewPassword: FC<PasswordProps> = ({
  onInput,
  name,
  id,
  validationFunction,
  ...rest
}) => {
  const [value, setValue] = useState('');

  const handleOnInput = (e: React.FormEvent<HTMLInputElement>) => {
    const el = e.target as HTMLInputElement;
    setValue(el.value);
    onInput && onInput(e);
  };

  const validationRules: ValidationRule[] = [
    {
      pattern: LOWERCASE_REGEX,
      label: 'Password must contain at least one lowercase letter',
    },
    {
      pattern: UPPERCASE_REGEX,
      label: 'Password must contain at least one uppercase letter',
    },
    {
      pattern: LENGTH_REGEX,
      label: 'Password must be at least 8 characters long',
    },
    {
      pattern: SPECIAL_CHARS_REGEX,
      label: 'Password must contain at least one special character',
    },
  ];

  return (
    <>
      <Password
        variant="outlined"
        margin="normal"
        autoComplete="none"
        onInput={handleOnInput}
        name={name}
        id={id}
        validationFunction={validationFunction}
        validationRules={validationRules}
        {...rest}
      />
      <Password
        {...rest}
        id={id + '-repeat'}
        variant="outlined"
        margin="normal"
        label="Repeat password"
        autoComplete="none"
        required
        errorMessages={{
          valueMissing: 'Please repeat your password',
          patternMismatch: 'Passwords do not match',
        }}
        inputProps={{
          pattern: new RegExp(`^${value}$`).source,
        }}
      />
    </>
  );
};

export { NewPassword };
