import { Avatar, Button, Typography } from '@mui/material';
import UserView from 'views/UserView';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const ForgottenPasswordComplete = () => (
  <UserView>
    <Avatar
      className="avatar"
      sx={(theme) => ({ bgcolor: theme.palette.secondary.main })}
    >
      <LockOutlinedIcon />
    </Avatar>
    <Typography component="h1" variant="h5">
      The password has been reset!
    </Typography>
    <Button variant="contained" color="secondary" href="/login">
      Return to login!
    </Button>
  </UserView>
);

export { ForgottenPasswordComplete };
