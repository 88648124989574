import Header from 'components/Header';
import { useAuth } from 'hooks/useAuth';
import { FC } from 'react';
import { useNavigate } from 'react-router';

const HeaderContainer: FC = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const handleLogout = () =>
    logout().then(() => navigate('/', { replace: true }));

  return <Header onLogout={handleLogout} hasUserMenu={!!user} />;
};

export default HeaderContainer;
