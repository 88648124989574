import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, Grid, Link, useTheme } from '@mui/material';
import { Form } from 'components/form';
import { FormErrorMessage } from 'components/form/formErrorMessage';
import { SubmitButton } from 'components/submitButton';
import AttachmentContainer from 'containers/AttachmentContainer';
import { UploadedItem } from 'contexts/MediaContext';
import { ProfileDTO } from 'generated/profile/models/ProfileDTO';
import useProfile from 'hooks/useProfile';
import { FC, ReactNode, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const ATTACHMENT_TYPE_LABELS = {
  CV: 'CV',
  DBS: 'DBS',
  Visa: 'Visa',
  Passport: 'Passport',
  POQ: 'Proof of qualification',
  POT: 'Proof of training',
  Immunisation: 'Immunisation',
  Indemnity: 'Indemnity',
  OTHER: 'Other',
} as const;

export type AttachmentTypeType = keyof typeof ATTACHMENT_TYPE_LABELS;
type AttachmentInstanceType = {
  filename?: string;
  progress?: number;
  error?: Error;
  required?: boolean;
};

export interface AttachmentType {
  type: AttachmentTypeType;
  instances: AttachmentInstanceType[];
  minItems: number;
  maxItems: number;
  helpModal?: ReactNode;
}

interface DocumentsControlProps {
  onSubmit: (data: any) => Promise<void>;
  onUploadComplete: (uploadedItem: UploadedItem) => Promise<ProfileDTO>;
  onFileRemoved: (type: AttachmentTypeType, filename: string) => void;
  onDownload: (type: AttachmentTypeType, filename: string) => void;
  attachments?: AttachmentType[];
}

const DocumentsControl: FC<DocumentsControlProps> = ({
  onSubmit,
  attachments = [],
  onUploadComplete,
  onFileRemoved,
  onDownload,
}) => {
  const { hasValidReferences } = useProfile();

  const checkUploadValidity = (fileName: string, type: AttachmentTypeType) => {
    return new Promise<boolean>((resolve, reject) => {
      const fileExists = attachments.some((attachment) => {
        return attachment.instances.some(
          (instance) => instance.filename + attachment.type === fileName + type,
        );
      });

      if (fileExists) {
        reject(
          new Error('Upload error', {
            cause: `${ATTACHMENT_TYPE_LABELS[type]}: a file with this name has already been uploaded!`,
          }),
        );
      } else {
        resolve(true);
      }
    });
  };

  const mapAttachmentInstance = ({
    instance,
    attachment,
    index,
  }: {
    instance: AttachmentInstanceType;
    attachment: AttachmentType;
    index: number;
  }): ReactNode => (
    <Grid
      item
      display={'flex'}
      justifyContent={'center'}
      xs={6}
      sm={4}
      key={`${attachment.type}/${index}`}
    >
      <AttachmentContainer
        label={ATTACHMENT_TYPE_LABELS[attachment.type]}
        onUploadComplete={onUploadComplete}
        helpModal={attachment.helpModal}
        onRemove={(filename) => onFileRemoved(attachment.type, filename)}
        onDownload={(filename) => onDownload(attachment.type, filename)}
        checkValidity={checkUploadValidity}
        filename={instance.filename}
        type={attachment.type}
        required={instance.required}
        error={instance.error}
      />
    </Grid>
  );

  const controls = useMemo(
    () =>
      attachments.reduce((acc, attachment) => {
        acc.push(
          ...attachment.instances.map((instance, index) => {
            return mapAttachmentInstance({
              instance: {
                ...instance,
                required: index < attachment.minItems,
              },
              attachment,
              index,
            });
          }),
        );
        if (attachment.instances.length < attachment.maxItems) {
          acc.push(
            mapAttachmentInstance({
              instance: {
                required: attachment.minItems > attachment.instances.length,
              },
              attachment,
              index: attachment.instances.length,
            }),
          );
        }

        return acc;
      }, [] as ReactNode[]),
    [attachments],
  );

  const handleOnSubmit = (data: any) => {
    if (hasValidReferences) {
      return onSubmit(data);
    }
    return Promise.reject(new Error('Please provide your references details'));
  };

  const theme = useTheme();

  return (
    <Form onSubmit={handleOnSubmit}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Grid container spacing={2}>
          {controls}
        </Grid>
        <FormErrorMessage />
        <Box
          display="flex"
          gap={2}
          justifyContent="space-between"
          paddingY={2}
          paddingX={4}
          bgcolor={theme.palette.grey[100]}
          borderRadius={1}
        >
          <Link
            component={RouterLink}
            to={'/profile/REFERENCES'}
            display={'flex'}
            alignItems={'center'}
            flexBasis={0}
          >
            <ChevronLeftIcon /> Previous
          </Link>
          <SubmitButton>Continue</SubmitButton>
        </Box>
      </Box>
    </Form>
  );
};

export default DocumentsControl;
